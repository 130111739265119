import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import CustomArrow from 'components/product-specials-carousel/product-specials-carousel-arrow';
import CustomDot from 'components/product-specials-carousel/product-specials-carousel-dot';

const ProductSpecialsCarouselControls = ({ next, previous, goToSlide, ...rest }) => {
  const {
    dotCount = 0,
    dotColor,
    carouselState: { currentSlide },
  } = rest;

  // don't show dots for 0 or one slide(s).
  if (dotCount <= 1) {
    return null;
  }

  return (
    <Container className='carousel-button-group'>
      <ArrowContainer>
        <CustomArrow variant='left' onClick={() => previous()} disabled={currentSlide === 0} />
      </ArrowContainer>
      <DotContainer>
        {_.times(dotCount, (idx) => (
          <CustomDot key={idx} color={dotColor} onClick={() => goToSlide(idx)} active={idx === currentSlide} />
        ))}
      </DotContainer>
      <ArrowContainer>
        <CustomArrow variant='right' onClick={() => next()} disabled={currentSlide + 1 === dotCount} />
      </ArrowContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DotContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;

  margin: 0px 5px;
`;

const ArrowContainer = styled.div`
  flex-shrink: 1;
`;

export default ProductSpecialsCarouselControls;
