import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';
import usePaths from 'src/hooks/use-paths';

import { Link } from 'components/core';

const ProductSpecialsCarouselCard = ({ special = {}, linkColor }) => {
  const { _id: specialId, menuDisplayName, name, specialType } = special;
  const { t } = useTranslation();
  const specialTypeUrlText = specialType === `bogo` ? `offer` : `sale`;
  const { href, route } = usePaths({ specials: true, id: `${specialTypeUrlText}/${specialId}` });
  const theme = useTheme();

  return (
    <Container>
      <MenuHeader>
        <MenuDisplayName>{menuDisplayName ?? name}</MenuDisplayName>
        <PipeContainer>|</PipeContainer>
        <Link href={href} route={route}>
          {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid */}
          <StyledAnchor color={_.isEmpty(linkColor) ? theme.customized.colors.buttonsLinks : linkColor}>
            {t('special-offers-carousel-card.shopOfferLink', 'Shop offer')}
          </StyledAnchor>
        </Link>
      </MenuHeader>
      <MenuDisplayDescription>
        {t(
          'special-offers-carousel-card.description',
          'This item is included in a special today! Add it to your cart to work towards completing the offer.'
        )}
      </MenuDisplayDescription>
    </Container>
  );
};

export default ProductSpecialsCarouselCard;

const fontMixin = css`
  font-size: 13px;
  line-height: 165%;
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

const MenuHeader = styled.p`
  ${fontMixin}

  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 14px 0px 6px;
`;

const MenuDisplayName = styled.span`
  font-weight: bold;
`;

const PipeContainer = styled.span`
  display: inline-block;
  margin: 0px 8px;
`;

const MenuDisplayDescription = styled.p`
  ${fontMixin}
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.grey[30]};
`;

const StyledAnchor = styled.a`
  color: ${({ color }) => color};
`;
