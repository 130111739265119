import React, { useState } from 'react';
import styled from 'styled-components';
import _range from 'lodash/range';
import { makeStyles } from '@material-ui/core/styles';

import useTranslation from 'hooks/use-translation';

import { TextInput } from 'src/components/core';
import { Select, Option } from 'src/components/core/select';

const useStyles = makeStyles(() => ({
  paper: {
    width: 85,
    marginTop: 12,
  },
  list: {
    '& li': {
      height: `35px`,
      minHeight: `0`,
    },
  },
}));

export default function QuantitySelect({ onChange, value, quantityDropdownOptions, enableMaxQuantityOption = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [enableNumberInput, setEnableNumberInput] = useState(false);

  return enableMaxQuantityOption && enableNumberInput ? (
    <div>
      <StyledNumberInput
        type='number'
        inputProps={{ min: 1, 'data-testid': 'max-quantity-input' }}
        placeholder='1'
        onChange={onChange}
        autoFocus
      />
    </div>
  ) : (
    <StyledSelect
      MenuProps={{ classes: { paper: classes.paper, list: classes.list } }}
      onChange={onChange}
      value={value}
      SelectDisplayProps={{
        'aria-label': t('common.quantity', 'Quantity'),
        'data-testid': `quantity-select`,
      }}
    >
      {_range(1, quantityDropdownOptions + 1).map((num) => (
        <Option key={num} value={num} data-testid='quantity-select-option'>
          {num}
        </Option>
      ))}

      {enableMaxQuantityOption && (
        <Option onClick={() => setEnableNumberInput(true)} value={0} data-testid='quantity-select-option-max'>
          {quantityDropdownOptions}+
        </Option>
      )}
    </StyledSelect>
  );
}

const StyledNumberInput = styled(TextInput)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.customized.radius.buttons} !important;
  border: 1px solid ${({ theme }) => theme.colors.grey[70]} !important;
  height: 53px;
  margin-right: 15px;
  padding: 0 15px;
  width: 100px;

  input {
    font-size: 18px;

    // disable the arrows on the number input
    appearance: textfield;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
`;

const StyledSelect = styled(Select)`
  border-radius: ${({ theme }) => theme.customized.radius.buttons} !important;
  font-size: 18px;
  height: 53px;
  margin-right: 15px;
  width: 100px;

  svg {
    right: 18px;
    margin-top: 8px;
  }

  & .MuiSelect-root {
    padding-right: 51px !important;
    border-radius: 32px !important;
  }

  & .MuiSelect-select:focus {
    background-color: inherit;
  }

  & .MuiOutlinedInput-input {
    font-size: 18px;
  }
`;
