import { useEffect } from 'react';
import cloneDeep from 'lodash/fp/cloneDeep';

import {
  productImpression,
  productAddedToCart,
  productRemovedFromCart,
  menuInteraction,
  cartDidViewCheckout,
  cartDidPurchase,
} from 'utils/ecommerce-analytics';

// Unused, but here if needed
export default function useEcommerceAnalytics() {
  return {
    productImpression,
    productAddedToCart,
    productRemovedFromCart,
    menuInteraction,
    cartDidViewCheckout,
    cartDidPurchase,
  };
}

export function useViewedCheckoutAnalytics({ cart }) {
  useEffect(() => {
    if (cart.isReady) {
      cartDidViewCheckout(cloneDeep(cart));
    }
  }, [cart.isReady]);
}

export function useViewedProductAnalytics({ product, cart }) {
  useEffect(() => {
    productImpression(product, cart);
  }, [product]);
}
