import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import filteredSpecials from 'shared/graphql/special/queries/filtered-specials.gql';
import filteredSpecialsV2 from 'shared/graphql/special/queries/filtered-specials-v2.gql';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function useProductOffers(product) {
  const flags = useFlags();
  const useV2Query = flags['fintech.cats.use-filteredSpecialsV2-outside-admin.rollout'];

  const { data: queryResults, loading } = useQuery(useV2Query ? filteredSpecialsV2 : filteredSpecials, {
    fetchPolicy: 'network-only',
    skip: _.isEmpty(product.specialData?.bogoSpecials),
    variables: {
      specialsFilter: {
        dispensaryId: product.DispensaryID,
        current: true,
        specialIds: _.map(product.specialData?.bogoSpecials || [], 'specialId'),
      },
    },
  });

  return {
    specials: queryResults?.filteredSpecialsV2?.specials || queryResults?.filteredSpecials?.specials || [],
    loading,
  };
}
