import React, { useState } from 'react';
import styled from 'styled-components';
import { GqlProducts } from 'types/graphql';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { cannabinoidSectionCopy } from 'shared/constants/products';
import { useAmplitude, AmplitudeEvents, AmplitudeCategory } from 'shared/hooks/use-amplitude';

import CannabinoidGrid from '../cannabinoid-grid';
import CannabinoidModal from '../cannabinoid-modal';
import { NoidType } from '../cannabinoids-util';

type CannabinoidsViewProps = {
  cannabinoids: NoidType[];
  product: GqlProducts;
};

function Cannabinoids({ cannabinoids, product }: CannabinoidsViewProps): JSX.Element {
  const [activeNoid, setActiveNoid] = useState<NoidType['cannabinoid'] | null>(null);

  const amplitude = useAmplitude();
  const { dispensary } = useDispensary();

  const closeModal = (): void => setActiveNoid(null);

  const handleNoidSelect = (noid: NoidType['cannabinoid']): void => {
    setActiveNoid(noid);

    amplitude.log(AmplitudeEvents.menu.clickedIndividualCannabinoid, {
      productId: product.id,
      cannabinoidName: noid.name,
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
      category: AmplitudeCategory.menu,
      description: 'User clicks a specific cannabinoid within the product detail page',
    });
  };

  return (
    <>
      {!!activeNoid && <CannabinoidModal handleClose={closeModal} {...activeNoid} />}
      <Description>{cannabinoidSectionCopy}</Description>
      <CannabinoidGrid handleNoidSelect={handleNoidSelect} cannabinoids={cannabinoids} />
    </>
  );
}

export default Cannabinoids;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 14px;
  line-height: 24.5px;
  padding-bottom: 36px;
`;
