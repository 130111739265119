import React from 'react';
import styled, { useTheme } from 'styled-components';
import _ from 'lodash';

const hexToRgb = (hex) =>
  // eslint-disable-next-line lodash/prefer-lodash-method
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const ProductSpecialsCarouselDot = ({ color = '', onClick, ...rest }) => {
  const { index, active, children } = rest;
  const theme = useTheme();

  return (
    <StyledDot
      color={hexToRgb(_.isEmpty(color) ? theme.customized.colors.buttonsLinks : color)}
      type='button'
      className={active ? 'active' : 'inactive'}
      onClick={onClick}
    >
      {React.Children.toArray(children)[index]}
    </StyledDot>
  );
};

export default ProductSpecialsCarouselDot;

const StyledDot = styled.button`
  display: inline-block;
  opacity: 1;
  box-shadow: none;
  transition: background 0.5s;
  outline: 0;
  cursor: pointer;

  padding: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  background: ${({ theme }) => theme.colors.grey[70]};
  margin: 0px 5.5px;

  &.active {
    background: ${({ color }) => `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`};
    border-color: rgba(255, 255, 255, 0.8);
  }
`;
