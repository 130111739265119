/* eslint-disable max-len */
import React from 'react';

export const BellDisabledIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <g clipPath='url(#clip0_1054_4393)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.272413 0.270949C0.516491 0.0268714 0.912219 0.026871 1.1563 0.270949L19.7277 18.8423C19.9718 19.0864 19.9718 19.4821 19.7277 19.7262C19.4836 19.9703 19.0879 19.9703 18.8438 19.7262L0.272414 1.15483C0.0283362 0.910755 0.0283359 0.515027 0.272413 0.270949Z'
        fill='#121516'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.07026 2.12984C6.3777 0.822405 8.15097 0.0878906 9.99998 0.0878906C11.849 0.0878906 13.6223 0.822407 14.9297 2.12984C16.237 3.43728 16.9716 5.21056 16.9716 7.05957V12.6129C16.9716 13.0784 17.1565 13.5248 17.4857 13.8539C17.8516 14.2198 18.1517 14.368 18.5715 14.368C18.9167 14.368 19.1965 14.6478 19.1965 14.993C19.1965 15.3382 18.9167 15.618 18.5715 15.618H15.0001C14.6549 15.618 14.3751 15.3382 14.3751 14.993C14.3751 14.6478 14.6549 14.368 15.0001 14.368H16.2874C15.9219 13.86 15.7216 13.2468 15.7216 12.6129V7.05957C15.7216 5.54211 15.1188 4.08678 14.0458 3.01373C14.0458 3.01373 14.0459 3.01374 14.0458 3.01373M14.0458 3.01373C12.9728 1.94072 11.5175 1.33789 9.99998 1.33789C8.48249 1.33789 7.02716 1.94071 5.95414 3.01373C5.51231 3.45555 5.15025 3.96214 4.87712 4.51123C4.7234 4.82029 4.34824 4.94621 4.03918 4.79248C3.73012 4.63876 3.6042 4.2636 3.75793 3.95454C4.09072 3.28549 4.53194 2.66816 5.07026 2.12984'
        fill='#121516'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.9465 19.2871C7.9465 18.942 8.22632 18.6621 8.5715 18.6621H11.4286C11.7738 18.6621 12.0536 18.942 12.0536 19.2871C12.0536 19.6323 11.7738 19.9121 11.4286 19.9121H8.5715C8.22632 19.9121 7.9465 19.6323 7.9465 19.2871Z'
        fill='#121516'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.65336 7.23074C3.99853 7.23074 4.27836 7.51057 4.27836 7.85574V12.6129C4.27836 13.2468 4.07809 13.86 3.71259 14.368H10.7144C11.0595 14.368 11.3394 14.6478 11.3394 14.993C11.3394 15.3382 11.0595 15.618 10.7144 15.618H1.42864C1.08346 15.618 0.803641 15.3382 0.803641 14.993C0.803641 14.6478 1.08346 14.368 1.42864 14.368C1.84834 14.368 2.14832 14.2199 2.51432 13.8539C2.51431 13.8539 2.51433 13.8539 2.51432 13.8539M2.51432 13.8539C2.84344 13.5248 3.02836 13.0784 3.02836 12.6129V7.85574C3.02836 7.51057 3.30818 7.23074 3.65336 7.23074'
        fill='#121516'
      />
    </g>
    <defs>
      <clipPath id='clip0_1054_4393'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
