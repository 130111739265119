import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import Effect from './effect-tile';

export default function Effects(props) {
  const { effects, isMobile } = props;

  return (
    <Container isMobile={isMobile}>
      {_.map(effects, (effect) => (
        <Effect isMobile={isMobile} effect={effect} key={effect} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  margin: ${({ isMobile }) => (isMobile ? `-7px` : `-10px`)};
`;
