import React from 'react';
import styled from 'styled-components';
import _map from 'lodash/map';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Select, Option } from 'components/core/select';

const useStyles = makeStyles(() => ({
  paper: {
    width: `auto`,
    minWidth: `85px`,
    marginTop: 12,
  },
  list: {
    '& li': {
      height: `35px`,
      minHeight: `0`,
    },
  },
}));

export default function AdditionalOptionsSelect({ onChange, value, options }) {
  const classes = useStyles();
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <StyledSelect
      MenuProps={{ classes: { paper: classes.paper, list: classes.list } }}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      renderValue={(option) => (option.length > 12 && isMobileView ? `${option.substring(0, 12)}...` : option)}
    >
      {_map(options, (opt) => (
        <Option key={opt} value={opt}>
          {opt}
        </Option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  width: auto;
  min-width: 100px;
  height: 53px;
  margin-right: 15px;
  border-radius: 32px !important;
  font-size: 18px;

  svg {
    right: 18px;
    margin-top: 8px;
  }

  & .MuiSelect-root {
    padding-right: 51px !important;
    border-radius: 32px !important;
  }

  & .MuiSelect-select:focus {
    background-color: inherit;
  }

  & .MuiOutlinedInput-input {
    font-size: 18px;
  }
`;
