import { useMutation } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import _ from 'lodash';
import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import useStores from 'shared/hooks/use-stores';
import { Link } from 'src/components/core';
import useBackInStock from 'src/hooks/use-back-in-stock';
import styled from 'styled-components';
import { space } from 'styled-system';

import useUser from 'hooks/use-user';
import { linkColors } from 'shared/constants/menu-colors';
import { POTENCY_TYPES, cbdDescription, tacDescription, thcDescription } from 'shared/constants/products';
import { recordRecentlyViewed as recordRecentlyViewedMutation } from 'shared/graphql/product/mutations';
import {
  filterLegacyEffects,
  getCategoryForProduct,
  getFormattedPotency,
  getQuantityRemaining,
  getTaxInclusionNote,
  isWeightedProduct,
  strainEffects,
} from 'shared/helpers/products';
import { getDescriptionHtml, isDescriptionHtmlBlank } from 'shared/utils/product-description-utils';
import { groupOptionsWithPrices } from 'src/utils/helpers/product';

import { ComingSoonChip, StaffPickChip } from 'components/chips';
import { HorizontalDivider } from 'components/core/divider';
import RichTextHtmlWrapper from 'shared/components/rich-text-html-wrapper';

import useCart from 'hooks/use-cart';
import { useViewedProductAnalytics } from 'hooks/use-ecommerce-analytics';
import useProductPrices from 'hooks/use-product-prices';
import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import usePaths from 'src/hooks/use-paths';

import { useAddToCartModal } from 'components/modals/add-to-cart';
import ProductSpecialsCarousel from 'components/product-specials-carousel';
import { useRouter } from 'next/router';
import { isExcludedCannabinoid } from 'shared/constants/cannabinoids';
import { getSpecialBadgeType, productIsPartOfActiveSpecial } from 'shared/helpers/specials';
import useProductOffers from 'src/hooks/use-product-offers';
import { useViewedProductMonitor } from 'utils/analytics/monitors/use-viewed-product-monitor';
import { useHardAgeGate } from 'src/hooks/use-hard-age-gate';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import { LoginCta } from 'src/components/ctas';
import { InfoChip } from '../info-chip';
import AdditionalOptionsSelect from './additional-options-select';
import BackInStock from './back-in-stock';
import { AddToCartButton, BuyingOptionsButton } from './buttons';
import {
  AddToCartContainer,
  AddToCartError,
  AddToCartErrorContainer,
  CarouselContainer,
  Content,
  Images,
  TaxText,
} from './layout';
import OptionsList from './options-list';
import { Price, PriceFrom } from './price';
import ProductDetails from './product-details';
import QuantitySelect from './quantity-select';
import { AvailableFrom, Brand, Description, Name } from './typography';

const TagTooltipWidth = '348px';

export default function Product(props) {
  const { queryParams } = useProductQueryParams();
  const { apolloClient } = useStores();
  const { product, dispensary } = props;
  const { isKiosk } = useUI();
  const { open: openAddToCartModal } = useAddToCartModal();
  const { t } = useTranslation();
  const { standardPrice, specialPrice, weightedSpecialPrices } = useProductPrices(product, queryParams.weight);
  const UI = useUI();
  const cart = useCart();
  const router = useRouter();

  const isMedical = useObserver(() => cart.isMedical);
  const { href, route } = usePaths();
  const taxInclusionNote =
    product.Status !== 'Archived' && getTaxInclusionNote({ product, dispensary, medical: isMedical });
  const menuType = useObserver(() => cart.menuType);
  const linkColorData = _.find(linkColors, ({ key }) => key === dispensary?.colorSettings?.linkColor);
  const dispensaryCarouselDotColor = useObserver(() => linkColorData?.background ?? linkColors?.['blue-4']?.background);
  const useRecordPageView = useDispensaryFlag(`rollout.recently-viewed-record-product-view`, dispensary?.id);
  const maxQuantityDropdownOptions = 10;

  useViewedProductAnalytics({ product, cart: _.cloneDeep(cart) });
  useViewedProductMonitor(product);

  const parsedOptions = groupOptionsWithPrices({ product, menuType, isKiosk });
  const allOptionsOutOfStock = parsedOptions.length === 0;
  const [selectedOption, setSelectedOption] = useState(parsedOptions?.[0]);
  const [selectedAdditionalOption, setAdditionalSelectedOption] = useState(product?.AdditionalOptions?.[0]);
  const [quantity, setQuantity] = useState(1);

  const isFeatured = product.featured?.current;
  const comingSoonProductsFlagEnabled = useDispensaryFlag(`rollout.coming-soon-products`, product.DispensaryID);
  const { hardAgeGate: hideMenuContent } = useHardAgeGate();
  const isComingSoon = comingSoonProductsFlagEnabled && product.comingSoon;
  const lowestPrice = !_.isNil(specialPrice) ? specialPrice : standardPrice;
  const quantityDropdownOptions = allOptionsOutOfStock ? 0 : parseQuantityRemaining(selectedOption.value);
  const totalQuantityAvailable = getTotalQuantityAvailable(selectedOption?.value);
  const isQuantityLimitMaxAvailableEnabled = dispensary?.storeSettings?.quantityLimitMaxAvailable ?? false;
  const enableMaxQuantityOption =
    totalQuantityAvailable > quantityDropdownOptions && isQuantityLimitMaxAvailableEnabled;
  const isQuantityOverMax = isQuantityLimitMaxAvailableEnabled && quantity > totalQuantityAvailable;
  const isUnavailable = product.Status !== 'Active' || product.isBelowThreshold || allOptionsOutOfStock;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));
  const { specials } = useProductOffers(product);

  const filteredEffects = filterLegacyEffects(product.effects);
  const hasEffects = !_.isEmpty(filteredEffects);
  const hasTerpenes = !_.isEmpty(product.terpenes) && !!product.terpenes.find((terp) => terp.value > 0);

  const hideDiscounts = useHideDiscountFromConsumer(dispensary);
  const showSpecialOffersCarousel = productIsPartOfActiveSpecial(product, menuType) && !hideDiscounts;

  // TODO - Clean up this mutation to useRecordRecentlyViewedMutation
  const [handleRecordPageView] = useMutation(recordRecentlyViewedMutation, {
    client: apolloClient,
    variables: {
      dispensaryId: dispensary?.id,
      productId: product.id,
    },
  });

  if (product.cannabinoidsV2) {
    product.cannabinoids = product.cannabinoidsV2;
  }
  const hasCannabinoids =
    !_.isEmpty(product.cannabinoids) &&
    !_.isEmpty(_.filter(product.cannabinoids, ({ cannabinoid }) => !isExcludedCannabinoid(cannabinoid?.name)));

  const hideEffects = _.get(dispensary, `storeSettings.hideEffects`, false);
  const hideTerpenes = _.get(dispensary, `storeSettings.hideTerpenes`, false);

  const hasDescription = !isDescriptionHtmlBlank(getDescriptionHtml(product));
  const hasBrandDescription = !hideMenuContent && !_.isEmpty(_.trim(product.brand?.description));
  const hasCertificateOfAnalysis = !_.isEmpty(product.POSMetaData?.canonicalLabResultUrl);
  const certificateOfAnalysisFlagEnabled =
    useDispensaryFlag('growth.ecomm.certificate_of_analysis.rollout', dispensary?.id) &&
    product.certificateOfAnalysisEnabled;
  const brandSlug = _.kebabCase(product.brand?.name) || product.brand?.id;
  const potency = getFormattedPotency(product);
  const user = useUser();
  const { backInStockSubscription, handleUnsubscribeProduct, handleSubscribeProduct } = useBackInStock(product, user);

  const tags = [];

  useEffect(() => {
    const handleKafkaMessage = async () => {
      if (useRecordPageView && dispensary) {
        await handleRecordPageView();
      }
    };
    handleKafkaMessage();
  }, [dispensary, handleRecordPageView, useRecordPageView]);

  if (product.strainType && _.isString(product.strainType) && product.strainType !== 'N/A') {
    tags.push({
      highlight: true,
      titleCase: true,
      id: `strainType`,
      queryValue: product.strainType.toLowerCase().replace(/\s+/g, '-'),
      text: product.strainType,
    });
  }

  if (potency.TAC && potency.TAC !== '--') {
    tags.push({
      id: `tac-content`,
      text: (
        <>
          <b>{POTENCY_TYPES.TAC}:</b> {potency.TAC}
        </>
      ),
      moreInfo: {
        infoTitle: t('marketplace.what-is-tac-header', 'What is TAC?'),
        infoBody: (
          <span>
            <b>{t('marketplace.tac-description-head', tacDescription.head)}&nbsp;</b>
            {t('marketplace.tac-description-body', tacDescription.body)}
          </span>
        ),
        tooltipDimensions: {
          width: TagTooltipWidth,
        },
      },
    });
  }

  if (potency.THC && potency.THC !== '--') {
    tags.push({
      id: `thc-content`,
      text: (
        <>
          <b>{POTENCY_TYPES.THC}:</b> {potency.THC}
        </>
      ),
      moreInfo: {
        infoTitle: t('marketplace.what-is-thc-header', 'What is THC?'),
        infoBody: (
          <span>
            <b>{t('marketplace.thc-description-head', thcDescription.head)}&nbsp;</b>
            {t('marketplace.thc-description-body', thcDescription.body)}
          </span>
        ),
        tooltipDimensions: {
          width: TagTooltipWidth,
        },
      },
    });
  }

  if (potency.CBD && potency.CBD !== '--') {
    tags.push({
      id: `cbd-content`,
      text: (
        <>
          <b>{POTENCY_TYPES.CBD}:</b> {potency.CBD}
        </>
      ),
      moreInfo: {
        infoTitle: t('marketplace.what-is-cbd-header', 'What is CBD?'),
        infoBody: (
          <span>
            <b>{t('marketplace.cbd-description-head', cbdDescription.head)}&nbsp;</b>
            {t('marketplace.cbd-description-body', cbdDescription.body)}
          </span>
        ),
        tooltipDimensions: {
          width: TagTooltipWidth,
        },
      },
    });
  }

  const hasTags = tags.length > 0;

  function handleToggleBuyingOptionsModal() {
    UI.productBuyingOptionsModal = product;
  }

  function getTotalQuantityAvailable(weight) {
    return getQuantityRemaining(product, weight, { isKiosk, defaultLimit: maxQuantityDropdownOptions });
  }

  function parseQuantityRemaining(weight) {
    return _.clamp(getTotalQuantityAvailable(weight), 1, maxQuantityDropdownOptions);
  }

  function handleOptionSelect(option) {
    const newQuantityRemaining = parseQuantityRemaining(option?.value);

    if (quantity > newQuantityRemaining && !isQuantityLimitMaxAvailableEnabled) {
      setQuantity(1);
    }

    setSelectedOption(option);
  }

  function handleQuantityChange({ target }) {
    setQuantity(Number(target.value));
  }

  const handleAddToCart = async () => {
    openAddToCartModal({
      quantity,
      additionalOption: selectedAdditionalOption,
      option: selectedOption.value,
      product,
      dispensary,
      trackerSource: UI.activeProductSource,
    });
  };

  const badgeType = getSpecialBadgeType(product, product?.specialData?.saleSpecials, product?.Options);
  const showWeightSpecials = badgeType !== `none`;

  async function handleCarouselClick(offerId) {
    await router.push(`/${UI.dispensaryRootUrl}/${dispensary?.cName}/specials/offer/${offerId}`);
  }

  async function navigateToStrainTypePage(tag) {
    // eslint-disable-next-line lodash/prefer-lodash-method
    const isInfoTextLink = tag.id === `strainType` && Object.keys(strainEffects).some((st) => st === tag.text);
    const dispensaryCName = dispensary?.cName;
    const category = getCategoryForProduct(product).key;

    if (isInfoTextLink && dispensaryCName && category) {
      await router.push(
        `/${UI.dispensaryRootUrl}/${dispensaryCName}/products/${category}?straintypes=${tag.queryValue}`
      );
    }
  }

  return (
    <Content isMobile={isMobile}>
      <Images product={product} isMobile={isMobile} isUnavailable={isUnavailable} />
      <Details isMobile={isMobile}>
        {isUnavailable && <OutOfStockText>Out of stock</OutOfStockText>}
        {product.brand && product.brand?.id && (
          <Brand isMobile={isMobile}>
            <Link route={`${route}/brands/${brandSlug}`} href={`${href}/brands/${brandSlug}`}>
              {product.brand.name}
            </Link>
          </Brand>
        )}
        <Name tag='h1' size='xlarge'>
          {product.name || product.Name}
        </Name>
        {(isFeatured || isComingSoon) && (
          <ChipContainer mb='16px'>
            {isFeatured && <StaffPickChip />}
            {isComingSoon && <ComingSoonChip />}
          </ChipContainer>
        )}
        {dispensary && (
          <>
            {isWeightedProduct(product) ? (
              <OptionsList
                onOptionSelect={handleOptionSelect}
                options={parsedOptions}
                product={product}
                selectedOption={selectedOption}
                showWeightSpecials={showWeightSpecials}
                weightedSpecialPrices={weightedSpecialPrices}
                disabled={isComingSoon || isUnavailable}
                hideDiscount={hideDiscounts}
              />
            ) : (
              <Price
                price={lowestPrice}
                isSpecial={!_.isNil(specialPrice)}
                standardPrice={standardPrice}
                isMobile={isMobile}
                isUnavailable={isUnavailable}
                hideDiscount={hideDiscounts}
              />
            )}

            {!UI.viewOnlyMode && !isComingSoon && !isUnavailable && (
              <AddToCartContainer>
                {!_.isEmpty(product?.AdditionalOptions) && (
                  <AdditionalOptionsSelect
                    options={product.AdditionalOptions}
                    value={selectedAdditionalOption}
                    onChange={setAdditionalSelectedOption}
                  />
                )}
                <QuantitySelect
                  onChange={handleQuantityChange}
                  value={quantity}
                  quantityDropdownOptions={quantityDropdownOptions}
                  enableMaxQuantityOption={enableMaxQuantityOption}
                />
                <ButtonWrapper isNewLine={!_.isEmpty(product?.AdditionalOptions)}>
                  <AddToCartButton
                    isMobile={isMobile}
                    onClick={handleAddToCart}
                    id='add-to-cart'
                    disabled={!quantity || isQuantityOverMax}
                  />
                </ButtonWrapper>
              </AddToCartContainer>
            )}
            {isQuantityOverMax && (
              <AddToCartErrorContainer includeBottomMargin={!(taxInclusionNote && !isUnavailable)}>
                <AddToCartError size='small' tag='div' data-testid='error-quantity-over-max'>
                  {totalQuantityAvailable} max quantity available
                </AddToCartError>
              </AddToCartErrorContainer>
            )}
            {taxInclusionNote && !isUnavailable && <TaxText>{taxInclusionNote}</TaxText>}
            {!isKiosk && (
              <BackInStock
                isUnavailable={isUnavailable}
                backInStockSubscription={backInStockSubscription}
                user={user}
                handleSubscribeProduct={handleSubscribeProduct}
                handleUnsubscribeProduct={handleUnsubscribeProduct}
                dispensary={dispensary}
              />
            )}
          </>
        )}
        {!dispensary && (
          <>
            <PriceFrom price={lowestPrice} />
            <HorizontalDivider mb={31} />
            <AvailableFrom numStores={7} />
            <BuyingOptionsButton onClick={handleToggleBuyingOptionsModal} />
          </>
        )}
        {showSpecialOffersCarousel && (
          <>
            <HorizontalDivider mb={isMobile ? 31 : 38} />
            <CarouselContainer>
              <ProductSpecialsCarousel
                specials={specials}
                dotColor={dispensaryCarouselDotColor}
                onClick={handleCarouselClick}
              />
            </CarouselContainer>
          </>
        )}
        {/* Tags & Description */}
        {(hasTags || hasDescription) && (
          <>
            <HorizontalDivider mb={isMobile ? 31 : 38} />
            {hasTags && (
              <Tags isMobile={isMobile}>
                {_.map(tags, (tag) => (
                  <InfoChip key={tag.id} {...tag} onClick={() => navigateToStrainTypePage(tag)} />
                ))}
              </Tags>
            )}
            {hasDescription && (
              <Description>
                <RichTextHtmlWrapper html={getDescriptionHtml(product)} />
              </Description>
            )}
          </>
        )}
        {hasCertificateOfAnalysis && certificateOfAnalysisFlagEnabled && (
          <CertificateOfAnalysisLink href={product.POSMetaData.canonicalLabResultUrl} target='_blank' rel='noreferrer'>
            Certificate of Analysis
          </CertificateOfAnalysisLink>
        )}
        <ProductDetails
          product={product}
          hasEffects={hasEffects}
          hideEffects={hideEffects}
          hasTerpenes={hasTerpenes}
          hideTerpenes={hideTerpenes}
          hideDiscounts={hideDiscounts}
          hasCannabinoids={hasCannabinoids}
          hasBrandDescription={hasBrandDescription}
          isMobile={isMobile}
        />

        <LoginCta />
      </Details>
    </Content>
  );
}

const Details = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  margin: ${({ isMobile }) => (isMobile ? `0 28px` : 0)};
  margin-top: 15px;
  margin-bottom: 32px;
  width: 100%;
`;

const CertificateOfAnalysisLink = styled.a`
  color: #3287e1;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 2rem;
`;

const ChipContainer = styled.div`
  ${space}

  .MuiChip-root {
    font-size: 14px;
    padding: 3px;
    margin-right: 8px;
  }
`;

const Tags = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  margin-bottom: ${({ isMobile }) => (isMobile ? `23px` : `32px`)};
`;

const ButtonWrapper = styled.div`
  @media only screen and (max-width: 450px) {
    margin-top: ${({ isNewLine }) => (isNewLine ? `15px` : `0px`)};
  }
`;

const OutOfStockText = styled.div`
  color: ${({ theme }) => theme.colors.grey[60]};
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;
