/* eslint-disable max-len */
import React from 'react';

export const BellIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.15713 16.692C8.48833 16.5947 8.83564 16.7844 8.93287 17.1156C9.00068 17.3466 9.14148 17.5494 9.3342 17.6937C9.52691 17.838 9.76118 17.916 10.0019 17.916C10.2427 17.916 10.4769 17.838 10.6696 17.6937C10.8624 17.5494 11.0032 17.3466 11.071 17.1156C11.1682 16.7844 11.5155 16.5947 11.8467 16.692C12.1779 16.7892 12.3676 17.1365 12.2704 17.4677C12.1265 17.9579 11.8277 18.3882 11.4188 18.6944C11.0098 19.0005 10.5128 19.166 10.0019 19.166C9.49109 19.166 8.994 19.0005 8.58507 18.6944C8.17613 18.3882 7.87736 17.9579 7.73348 17.4677C7.63625 17.1365 7.82592 16.7892 8.15713 16.692Z'
      fill='white'
    />
    <path
      d='M10 1.66675C11.6576 1.66675 13.2473 2.32523 14.4194 3.49733C15.5915 4.66943 16.25 6.25914 16.25 7.91675C16.25 13.7884 17.5 14.7917 17.5 14.7917H2.5C2.5 14.7917 3.75 13.1951 3.75 7.91675C3.75 6.25914 4.40848 4.66943 5.58058 3.49733C6.75268 2.32523 8.3424 1.66675 10 1.66675V1.66675Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
