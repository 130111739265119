import React, { useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { mediaQueries } from 'shared/styles';

import CannabinoidGridItem from '../cannabinoid-grid-item';
import { NoidType, pruneAndSum, cannabinoidSort } from '../cannabinoids-util';

type NoidGridProps = {
  cannabinoids: NoidType[];
  handleNoidSelect(noid: NoidType['cannabinoid']): void;
};

function CannabinoidGrid({ cannabinoids, handleNoidSelect }: NoidGridProps): JSX.Element {
  const [sortedNoids, totalValue] = useMemo(() => {
    const [pruned, sum] = pruneAndSum(cannabinoids);
    return [cannabinoidSort(pruned), sum];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // FIXME: ENG-32714 fix hooks dependency

  return (
    <Grid>
      {_.map(sortedNoids, (noid) => (
        <CannabinoidGridItem
          totalValue={totalValue}
          handleSelect={handleNoidSelect}
          {...noid}
          key={noid.cannabinoid.name}
        />
      ))}
    </Grid>
  );
}

export default CannabinoidGrid;

const Grid = styled.section`
  display: grid;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  grid-column-gap: 72px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  margin-left: -4px; // account for padding in noid icons

  @media ${mediaQueries.phone} {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 32px 0 8px;
  }
`;
