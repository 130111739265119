import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { HorizontalDivider } from 'components/core/divider';

import { Accordion, AccordionSummary, AccordionActions } from 'shared/components';
import ChevronIcon from 'shared/assets/accordion-chevron';
import { Subheading } from '../../typography';

type DetailAccordionProps = {
  id: string;
  title: ReactNode;
  children: React.ReactNode;
  isMobile: boolean;
  isFirstSection: boolean;
  isLastSection: boolean;
};

const DetailAccordion = forwardRef((props: DetailAccordionProps, ref) => {
  const { id, title, children, isMobile, isLastSection, isFirstSection } = props;

  return (
    <>
      <HorizontalDivider mt={isFirstSection ? 39 : 2} mb={28} />
      <AccordionHolder id={id} ref={ref}>
        <StyledAccordion defaultExpanded>
          <StyledAccordionSummary expandIcon={<StyledChevronIcon />} classes={{ expanded: `expanded` }}>
            <HeaderText>
              <Subheading isMobile={isMobile}>{title}</Subheading>
            </HeaderText>
          </StyledAccordionSummary>
          <StyledAccordionActions>
            <ExpansionContent isLastSection={isLastSection}>{children}</ExpansionContent>
          </StyledAccordionActions>
        </StyledAccordion>
      </AccordionHolder>
    </>
  );
});

export default DetailAccordion;

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    min-width: 100%;
    min-height: 0;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  & .MuiAccordionSummary-root {
    height: 32px;
  }
  & .MuiAccordionSummary-root,
  & .MuiAccordionSummary-root.Mui-expanded {
    padding: 0;
    margin: 0 0 20px 0;
    min-height: 0;
  }
`;

const AccordionHolder = styled.div`
  & .expanded > .expanded:first-child {
    margin: 12px 0px !important;
  }
  & .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(180deg);
  }
  & .MuiAccordionSummary-expandIcon {
    transform: rotate(0deg);
  }
`;

const StyledChevronIcon = styled(ChevronIcon)`
  height: 8.5px;
  width: 14px;
  margin-left: 7px;
  fill: ${({ theme }) => theme.colors.grey[45]};
`;

const ExpansionContent = styled.div`
  width: 100%;
  padding: 0 0 4px;
  margin-bottom: ${({ isLastSection }) => (isLastSection ? '75px' : '32px')};
  position: relative;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: static;
  }
`;

const StyledAccordionActions = styled(AccordionActions)`
  padding: 0 !important;
`;

const HeaderText = styled.div`
  padding-top: 2px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  & .MuiAccordionSummary-root {
    display: flex;
  }
  & .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    min-height: 0;
  }

  & .MuiIconButton-label svg {
    margin: 0;
  }

  & .MuiAccordionSummary-content span {
    margin: 0;
  }
`;
