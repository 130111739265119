import React from 'react';
import styled from 'styled-components';

export default function Terpene({
  terpene,
  activeTerpene,
  handleFocusOn,
  handleFocusOff,
  handleTerpeneClick,
  hasValues,
  infoOpen,
}) {
  const { libraryTerpene, color, value, unitSymbol } = terpene;
  const name = libraryTerpene?.name || '';
  const isFaded = !!(activeTerpene && activeTerpene.libraryTerpene?.name !== name);

  return (
    value > 0 && (
      <Container>
        <Button
          onClick={(e) => handleTerpeneClick(e, terpene)}
          onFocus={() => handleFocusOn(terpene)}
          onMouseEnter={() => handleFocusOn(terpene)}
          onBlur={handleFocusOff}
          onMouseLeave={handleFocusOff}
          isFaded={isFaded}
          infoOpen={infoOpen}
          hasValues={hasValues}
        >
          <TextWrap>
            <Name indicatorColor={color} hasValues={hasValues} isFaded={isFaded} infoOpen={infoOpen}>
              {name}
            </Name>
            {hasValues && <Value>{value ? `${value}${unitSymbol !== '%' ? ' ' : ''}${unitSymbol}` : '--'}</Value>}
          </TextWrap>
        </Button>
      </Container>
    )
  );
}

const Container = styled.div`
  box-sizing: content-box;
  width: 100%;
  max-width: 150px;
  padding: 0 60px 33px 0;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 23px;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: ${({ hasValues }) => (hasValues ? `flex-start` : `center`)};
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  opacity: ${({ isFaded, infoOpen }) => (isFaded || infoOpen ? '0.5' : '1')};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
    padding: 0 35px 10px 0;
  }
`;

const TextWrap = styled.span`
  display: block;
`;

const Name = styled.span`
  display: block;
  position: relative;
  margin: 0 0 2px 0;
  font-weight: bold;
  line-height: 1;
  padding-left: 16px;
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 15px;
  }
  color: ${({ theme }) => theme.colors.grey[35]};

  &:before {
    content: ${({ hasValues, indicatorColor }) => (hasValues && indicatorColor ? '""' : '')};
    background-color: ${({ indicatorColor, isFaded, infoOpen }) => (isFaded || infoOpen ? `#BCCAD2` : indicatorColor)};
    display: block;
    position: absolute;
    top: 3px;
    left: 0px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
`;

const Value = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.grey[35]};
  font-size: 13px;
  line-height: 21px;
`;
