import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import Terpene from '../terpene';
import TerpeneInfo from '../terpene-info';

export default function TerpeneList({
  terpenes,
  activeTerpene,
  handleFocusOn,
  handleFocusOff,
  handleTerpeneClick,
  openedTerpene,
  openInfoModal,
  closeInfoModal,
  infoOpen,
  hasValues,
  isLastSection,
  isMobile,
  modalRef,
}) {
  // Sort the colored terpenes by highest value first (desc),
  // with a secondary alphabetical sort by name (asc).
  const sortedTerpenes = _.orderBy(terpenes, [({ value }) => value || '', ({ name }) => _.toLower(name)], [`desc`]);
  const showList = !isMobile || (isMobile && isLastSection) || (isMobile && !isLastSection && !infoOpen);

  return (
    <Container hasValues={hasValues}>
      {showList && (
        <ListWrap hasValues={hasValues}>
          {_.map(sortedTerpenes, (terpene) => (
            <Terpene
              key={terpene.id}
              terpene={terpene}
              activeTerpene={activeTerpene}
              handleFocusOn={handleFocusOn}
              handleFocusOff={handleFocusOff}
              handleTerpeneClick={handleTerpeneClick}
              openTerpene={openInfoModal}
              hasValues={hasValues}
              infoOpen={infoOpen}
            />
          ))}
        </ListWrap>
      )}
      <TerpeneInfo
        terpene={openedTerpene}
        isOpen={infoOpen}
        closeTerpene={closeInfoModal}
        isLastSection={isLastSection}
        modalRef={modalRef}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  max-width: ${({ hasValues }) => (hasValues ? `none` : `320px`)};
  width: 100%;
  margin: 0;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: ${({ hasValues }) => (hasValues ? `400px` : `500px`)};
    position: unset;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: none;
  }
`;

const ListWrap = styled.div`
  display: grid;
  gap: 0;
  @media (min-width: 280px) {
    grid-template-columns: auto auto;
  }
  @media (min-width: 500px) {
    grid-template-columns: auto auto auto;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: ${({ hasValues }) => (hasValues ? `auto auto` : `auto auto auto`)};
  }
`;
