import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import InfoIcon from 'src/assets/info-icon';
import { parseNoidAbbreviation } from 'shared/utils/misc-utils';
import { CannabinoidAbbrev } from 'shared/constants/cannabinoids';
import { Clickable } from 'shared/components';
import { NoidType, calcProgress, prettyValue } from '../cannabinoids-util';

type NoidGridItemProps = NoidType & {
  totalValue: number;
  handleSelect(noid: NoidType['cannabinoid']): void;
};
function CannabinoidGridItem({ cannabinoid, value, handleSelect, totalValue, unit }: NoidGridItemProps): JSX.Element {
  const { name } = cannabinoid;
  const abbrevName = CannabinoidAbbrev[parseNoidAbbreviation(name)];

  const progressAmount = useMemo(() => {
    const ofTotal = value / totalValue;
    return calcProgress(ofTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // FIXME: ENG-32714 fix hooks dependency

  return (
    <GridItem onClick={() => handleSelect(cannabinoid)}>
      <Identifier>
        <span>
          <b>{abbrevName}</b>
        </span>
        <TooltipIcon />
      </Identifier>
      <Amount>{prettyValue(value, unit)}</Amount>
      <ProgressBar>
        <ProgressAmount value={progressAmount} />
      </ProgressBar>
    </GridItem>
  );
}

export default CannabinoidGridItem;

const barStyles = css`
  border-radius: 8px;
`;

const ProgressAmount = styled.div`
  ${barStyles}
  background-color: ${({ theme }) => theme.colors.green[45]};
  position: absolute;
  left: 0;
  top: 0;
  width: ${({ value }) => value}%;
  height: 100%;
`;

const ProgressBar = styled.div`
  ${barStyles}
  grid-column: content;
  grid-row: bar;
  align-self: end;
  background-color: ${({ theme }) => theme.colors.grey[95]};
  position: relative;
  height: 8px;
  margin-bottom: 10px;
`;

const Amount = styled.div`
  grid-column: amount;
  grid-row: text;
  justify-self: end;
  font-size: 13px;
  margin-right: 2px; // account for progress bar border-radius
  color: ${({ theme }) => theme.colors.grey[30]};
`;

const TooltipIcon = styled(InfoIcon)`
  width: 14px;
  margin-left: 6px;
`;

const Identifier = styled.div`
  grid-column: identifier;
  grid-row: text;
  display: flex;
  font-size: 14px;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[30]};
  line-height: 1;
`;

/* eslint-disable max-len */
const GridItem = styled(Clickable)`
  display: inline-grid;
  grid-template-columns: [identifier-start content-start] auto [identifier-end amount-start] 1fr [amount-end content-end];
  grid-template-rows: [text-start both-start] auto [text-end bar-start] 8px [bar-end both-end];
  height: 43px;
  align-items: center;
  justify-content: start;
  color: ${({ theme }) => theme.colors.grey[35]};
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
`;
