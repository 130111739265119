import React from 'react';

function SvgRelaxed(props) {
  return (
    <svg fill='none' viewBox='0 0 50 51' {...props}>
      <path
        clipRule='evenodd'
        d='M8.685 23.951l-.65 13.394 1.86 5.356 5.624 5.798h9.122l7.408-3.288 3.884-5.867 3.325-.952 2.491-2.167v-6.768l-1.134-2.22-2.64-.807h-2.042v-2.479l-1.685-1.663H9.894z'
        fill='#fff'
        fillRule='evenodd'
      />
      <g stroke='#64737f' strokeWidth={2.782}>
        <path
          d='M16.529 22.343l.001 9.594M8.036 24.53c0-1.495.573-2.242 1.72-2.242h25.077c1.238 0 1.857.747 1.857 2.242v1.754c3.668.142 5.502.995 5.502 2.557v4.747c.138 2.493-.744 3.994-2.647 4.504-1.903.51-2.855.372-2.855-.414-.269.895-.614 1.759-1.034 2.592-.63 1.25-2.257 3.834-5.32 5.55-2.043 1.145-4.554 1.756-7.533 1.833-3.489.137-6.298-.473-8.428-1.832s-3.726-2.997-4.788-4.914c-1.034-1.877-1.551-3.966-1.551-6.269V24.53zm28.643 13.937V25.388z'
          strokeLinejoin='round'
        />
        <path
          clipRule='evenodd'
          d='M17.296 32.056H15.71c-.56-.05-.841.251-.841.902v4.048c-.004.596.276.894.841.894h3.17c.579.074.869-.332.869-1.22v-3.722c.027-.601-.262-.902-.87-.902h-1.584z'
        />
        <g strokeLinecap='round'>
          <path
            d='M15.846 5.69c.006 1.708-.324 2.96-.991 3.756-1 1.195-1.938 1.322-1.938 3.47s1.041 3.88 1.545 4.49'
            strokeLinejoin='round'
          />
          <path d='M21.522 1.786c1.182 1.542 1.871 2.807 2.066 3.793.195.987-.026 2.129-.663 3.426a105.858 105.858 0 01-1.843 3.15c-.625 1.008-.291 2.272 0 3.035.292.762.952 1.892 1.145 2.217' />
          <path
            d='M29.096 2.762c.66.879 1.07 1.672 1.225 2.38.233 1.062.219 2.18 0 3.115-.146.623-.496 1.246-1.05 1.867-.828.792-1.33 1.516-1.509 2.17-.267.983-.267 1.362 0 2.633.178.847.623 1.674 1.334 2.48'
            strokeLinejoin='round'
          />
          <path d='M9.012 48.648h26.36' />
        </g>
      </g>
    </svg>
  );
}

export default SvgRelaxed;
