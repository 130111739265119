import React from 'react';

function SvgEnergetic(props) {
  return (
    <svg fill='none' viewBox='0 0 31 50' {...props}>
      <g clipRule='evenodd' fillRule='evenodd'>
        <path d='M9.293 1.96L2.608 29.85h6.685L5.95 48.038 27.62 20.843l-9.08-.036 6.49-18.846z' fill='#fff' />
        <path
          d='M9.312.002C8.696.075 8.181.548 8.009 1.2L6.993 5.09c-.293.863.11 1.823.9 2.143.789.32 1.666-.12 1.96-.983.026-.078.047-.158.062-.24l.7-2.674H23.43l-6.21 16.01c-.333.846.024 1.826.797 2.19.19.09.394.136.6.137h6.734L8.835 41.677l3.113-11.2c.237-.884-.226-1.81-1.034-2.07a1.432 1.432 0 00-.426-.067H4.357l4.05-16.235c.223-.892-.257-1.813-1.072-2.057-.816-.245-1.659.28-1.882 1.171L.879 29.556c-.228.887.244 1.807 1.055 2.056a1.4 1.4 0 00.422.063h6.067l-4.48 16.183c-.239.883.221 1.81 1.028 2.073.561.182 1.168-.003 1.562-.475L29.91 21.117c.562-.687.508-1.74-.12-2.354a1.454 1.454 0 00-1.007-.424h-7.878l6.227-16.027c.324-.85-.043-1.827-.82-2.182a1.414 1.414 0 00-.578-.128H9.47a1.51 1.51 0 00-.159 0z'
          fill='#64737f'
        />
      </g>
    </svg>
  );
}

export default SvgEnergetic;
