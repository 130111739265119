import React from 'react';

function SvgCalm(props) {
  return (
    <svg fill='none' viewBox='0 0 47 51' {...props}>
      <g clipRule='evenodd' fill='#fff' fillRule='evenodd'>
        <path d='M8.505 48.762V14.13h12.34l1.747 1.915v5.464l15.01-.78 1.662 1.58v26.452zM15.97 10.09l-2.568-2.264L15.97.893l2.511 6.138zM30.703 17.024l-3.239-3.06 2.679-6.375 3.072 6.376z' />
      </g>
      <g stroke='#64737f'>
        <path
          d='M22.654 20.73v-4.702c0-1.058-.603-1.69-1.81-1.897H10.392c-1.499 0-2.152.825-1.958 2.475v32.156h30.124V22.718c0-1.31-.726-1.973-2.18-1.988zm0 0l-.062 26.963'
          strokeWidth={2.136}
        />
        <g strokeWidth={1.78}>
          <path d='M15.97 14.131l-.028-4.921' />
          <path
            clipRule='evenodd'
            d='M15.968.99c1.148 1.778 1.933 3.38 2.354 4.81.42 1.429.01 2.565-1.23 3.41h-2.243c-1.231-.733-1.676-1.87-1.336-3.41.34-1.541 1.158-3.144 2.455-4.81zM30.357 7.826c1.15 1.777 1.934 3.38 2.354 4.81.421 1.429.011 2.565-1.23 3.41h-2.243c-1.23-.733-1.675-1.87-1.335-3.41.34-1.541 1.158-3.145 2.454-4.81z'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M22.592 24.948c1.966 1.381 2.95 2.975 2.95 4.782s.972 2.634 2.918 2.483c1.85-.27 2.775-1.899 2.775-4.887s1.384-3.41 4.153-1.266c1.476.746 2.533.375 3.17-1.112M8.4 21.638c.875-2.2 2.543-3.3 5.002-3.3 2.46 0 3.69 1.119 3.69 3.355-.055 2.02.408 3.03 1.39 3.03s2.085-.432 3.313-1.297c.384-.22.759-.583.759-1.105M30.392 20.826v-5.2' />
        </g>
        <path d='M1.571 48.727h43.75' strokeLinecap='round' strokeWidth={2.67} />
      </g>
    </svg>
  );
}

export default SvgCalm;
