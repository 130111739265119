import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import { Typography } from 'src/typography';

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 13px;
  line-height: 22px;
  max-width: 564px;
  width: 100%;

  p {
    margin-bottom: 22px;
  }
`;

// TODO get rid of these isMobile flags
export const Subheading = styled((props) => <span {..._.omit(props, ['isMobile'])} role='heading' aria-level='4' />)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primaryBlack};
  color: #393d40;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: ${({ isMobile }) => (isMobile ? `12px` : `24px`)};
  text-transform: initial;
`;

export const Brand = styled.div`
  color: ${({ theme }) => theme.colors.grey[60]};
  cursor: pointer;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  text-transform: uppercase;

  a {
    &:hover {
      color: ${({ theme }) => theme.colors.grey[30]};
    }
  }
`;

export const Name = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-bottom: 7px;
`;

export function AvailableFrom(props) {
  const { numStores } = props;
  const { Trans } = useTranslation();

  return (
    <AvailableFromContainer>
      <Trans i18nKey='marketplace.available-from'>
        Available from&nbsp;<b>{{ numStores }} nearby stores</b>
      </Trans>
    </AvailableFromContainer>
  );
}

const AvailableFromContainer = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-bottom: 20px;

  b {
    font-weight: bold;
  }
`;
