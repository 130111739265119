import React from 'react';
import styled from 'styled-components';

import ChevronIcon from 'assets/chevron-icon';

const ProductSpecialsCarouselArrow = ({ onClick, ...rest }) => {
  const { variant = `left`, disabled = false } = rest;
  return (
    <ChevronIconContainer onClick={() => onClick()} variant={variant}>
      <StyledChevronIcon disabled={disabled} />
    </ChevronIconContainer>
  );
};

export default ProductSpecialsCarouselArrow;

const getRotateCSSRule = (degrees) => `rotate(${degrees}deg)`;

const ChevronIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  transform: ${({ variant }) => getRotateCSSRule(variant === `right` ? -90 : 90)};
`;

const StyledChevronIcon = styled(ChevronIcon)`
  width: 9.6px;
  height: 16px;

  fill: ${({ theme, disabled }) => theme.colors.blueGrey[disabled ? 90 : 80]};
`;
