import { isExcludedCannabinoid } from 'shared/constants/cannabinoids';
import { GqlProducts_CannabinoidV2 as GqlNoidType, PotencyUnit } from 'types/graphql';
import { AssumeExists } from 'shared/utils/type-utils';
import { POTENCY_UNITS_DISPLAY } from 'shared/constants/products';

export type NoidType = AssumeExists<GqlNoidType>;

export function cannabinoidSort(cannabinoids: NoidType[]): NoidType[] {
  return cannabinoids.sort((a, b) => {
    if (a.value > b.value) {
      return -1;
    }
    if (a.value < b.value) {
      return 1;
    }
    return a.cannabinoid.name.localeCompare(b.cannabinoid.name);
  });
}

export function pruneAndSum(cannabinoids: NoidType[]): [NoidType[], number] {
  const pruned: NoidType[] = [];
  const total = cannabinoids.reduce((sum, curr) => {
    if (!isExcludedCannabinoid(curr.cannabinoid.name)) {
      pruned.push(curr);
      sum += curr.value;
    }

    return sum;
  }, 0);
  return [pruned, total];
}

const MIN_BAR_PERCENTAGE = 2;

export const calcProgress = (portion: number): number => {
  const value = portion * 100;
  if (value > 0) {
    const valueOrMinBarPercentage = Math.max(value, MIN_BAR_PERCENTAGE);
    return Math.min(valueOrMinBarPercentage, 100);
  }

  return 0;
};

export const prettyValue = (value: number, unit: PotencyUnit): string => {
  const unitLabel = POTENCY_UNITS_DISPLAY[unit];
  return value > 0 ? `${value.toFixed(2)}${unitLabel}` : '--';
};
