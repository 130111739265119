/* eslint-disable max-len */
import React from 'react';

function SvgHappy(props) {
  return (
    <svg width='30' height='50' viewBox='0 0 51 50' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.95473 21.755C5.95473 21.755 11.3071 24.1183 15.9051 24.8952C20.5031 25.6721 24.0999 25.7398 25.1217 25.7398C26.1434 25.7398 30.0511 25.6469 31.2424 25.4678C32.4337 25.2886 35.3319 24.8952 37.7564 24.1015C40.181 23.3077 42.9806 22.2779 44.3452 21.4981C45.7099 20.7183 42.7917 27.1277 41.3164 28.6678C39.8412 30.208 39.8168 30.5297 35.7842 33.3522C31.7515 36.1746 25.3418 36.1076 25.3418 36.1076C25.3418 36.1076 19.8067 35.9592 15.9051 34.203C12.0035 32.4468 11.044 31.3521 8.61901 28.3202C6.19401 25.2883 5.95473 21.755 5.95473 21.755Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.95473 21.7549C5.95473 21.7549 11.3071 24.1183 15.9051 24.8952C20.5031 25.6721 24.0999 25.7398 25.1217 25.7398C26.1434 25.7398 30.0511 25.6469 31.2424 25.4678C32.4337 25.2886 35.3319 24.8952 37.7564 24.1015C40.181 23.3077 42.9806 22.2779 44.3452 21.4981C45.7099 20.7183 42.8014 26.8495 41.1829 28.7708C39.5644 30.6921 39.1209 31.7898 35.953 33.5715C32.785 35.3532 27.9782 36.2559 25.3418 36.1075C22.7055 35.9592 19.8067 35.9592 15.9051 34.203C12.0035 32.4468 11.044 31.3521 8.61901 28.3202C6.19401 25.2883 5.95473 21.7549 5.95473 21.7549Z'
        stroke='#64737F'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.9146 27.4766C41.9146 27.4766 37.555 30.1887 32.1479 30.1887'
        stroke='#64737F'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M18.0557 30.2726C18.0557 30.2726 13.2312 29.7208 8.20395 27.1593'
        stroke='#64737F'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M44.0193 18.0493C44.0193 18.0493 46.0073 17.1196 47.7331 18.6367C49.4588 20.1539 48.8338 22.7892 48.8338 22.7892'
        stroke='#64737F'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M6.98068 17.9593C6.98068 17.9593 4.99266 17.0296 3.26693 18.5467C1.5412 20.0638 2.16614 22.6992 2.16614 22.6992'
        stroke='#64737F'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default SvgHappy;
