import React, { useRef, useEffect, CSSProperties } from 'react';
import styled from 'styled-components';
import { ModalContainer, ModalExitIcon } from 'components/core';

import { Modal, Backdrop } from '@material-ui/core';
import miscUtils, { parseNoidAbbreviation, parseNoidLongName } from 'shared/utils/misc-utils';
import { mediaQueries } from 'shared/styles';
import { NoidType } from '../cannabinoids-util';

// Manually set focus to the modal (and w/o triggering focus scroll if the modal is already in view)
const focusModal = (ref): void => {
  if (ref.current) {
    if (miscUtils.isScrolledIntoView(ref.current, ref.current.clientHeight)) {
      ref.current.focus({ preventScroll: true });
    } else {
      ref.current.focus();
    }
  }
};

const modalStyle: CSSProperties = {
  position: 'absolute',
  top: '0',
  bottom: 'auto',
  minHeight: '100%',
};

type NoidModalProps = NoidType['cannabinoid'] & {
  handleClose(): void;
};

function CannabinoidModal({ name, description, handleClose }: NoidModalProps): JSX.Element {
  const abbrevName: string = parseNoidAbbreviation(name);
  const longName: string = parseNoidLongName(name);

  const ref = useRef();
  useEffect(() => {
    focusModal(ref);
  }, []);

  return (
    <Modal
      open
      disablePortal
      disableScrollLock
      aria-labelledby='noid-modal-title'
      aria-describedby='noid-modal-desc'
      disableEnforceFocus
      disableAutoFocus
      style={modalStyle}
      onClose={handleClose}
      BackdropComponent={BackdropComponent}
    >
      <StyledModalContainer ref={ref}>
        <Subtitle>{abbrevName}</Subtitle>
        <Title>{longName}</Title>
        <ModalExitIcon aria-label={`close-${abbrevName}-description-modal`} onClick={handleClose} />
        <Description>{description}</Description>
      </StyledModalContainer>
    </Modal>
  );
}

export default CannabinoidModal;

const BackdropComponent = styled(Backdrop)`
  &.MuiBackdrop-root {
    background-color: transparent;
  }
`;

const Description = styled.p`
  padding-top: 12px;
  grid-column: span 2;
  grid-row: body;
  justify-self: start;
  text-align: left;
  font-size: 14px;
  line-height: 24.5px;
  color: ${({ theme }) => theme.colors.grey[30]};
  @media ${mediaQueries.smallPhone} {
    font-size: 12px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  grid-column: label;
  grid-row: title;
  text-transform: capitalize;
  justify-self: start;
  margin-bottom: 10px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grey[30]};
  @media ${mediaQueries.smallPhone} {
    font-size: 16px;
  }
`;

const Subtitle = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[60]};
  grid-column: label;
  grid-row: top-line;
  font-size: 14px;
  font-weight: bold;
  justify-self: start;
  @media ${mediaQueries.smallPhone} {
    font-size: 11px;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  top: -7px;
  width: 100% !important;
  min-height: 175px;
  transform: none;
  border-radius: 24px;
  padding: 27px 40px 37px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.097543);
  display: grid;
  grid-template-rows: [top-line-start] auto [top-line-end title-start] auto [title-end body-start] 1fr [body-end];
  grid-template-columns: [content-start icon-start] auto [icon-end label-start] 1fr [label-end content-end];
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    top: -18px;
  }
`;
