import styled from 'styled-components';
import React from 'react';
import _ from 'lodash';

import CalmIcon from 'assets/effects/calm';
import ClearMindIcon from 'assets/effects/clear-mind';
import CreativeIcon from 'assets/effects/creative';
import EnergeticIcon from 'assets/effects/energetic';
import FocusedIcon from 'assets/effects/focused';
import HappyIcon from 'assets/effects/happy';
import InspiredIcon from 'assets/effects/inspired';
import RelaxedIcon from 'assets/effects/relaxed';
import SleepyIcon from 'assets/effects/sleepy';
import UpliftedIcon from 'assets/effects/uplifted';

const effectMapping = {
  calm: {
    Icon: CalmIcon,
    text: 'Calm',
  },
  clearMind: {
    Icon: ClearMindIcon,
    text: 'Clear mind',
  },
  creative: {
    Icon: CreativeIcon,
    text: 'Creative',
  },
  energetic: {
    Icon: EnergeticIcon,
    text: 'Energetic',
  },
  focused: {
    Icon: FocusedIcon,
    text: 'Focused',
  },
  happy: {
    Icon: HappyIcon,
    text: 'Happy',
  },
  inspired: {
    Icon: InspiredIcon,
    text: 'Inspired',
  },
  relaxed: {
    Icon: RelaxedIcon,
    text: 'Relaxed',
  },
  sleepy: {
    Icon: SleepyIcon,
    text: 'Sleepy',
  },
  uplifted: {
    Icon: UpliftedIcon,
    text: 'Uplifted',
  },
};

export default function Effect(props) {
  const { effect, isMobile } = props;

  const mapping = effectMapping[_.camelCase(effect)];

  if (!mapping) {
    return null;
  }
  return (
    <Container isMobile={isMobile}>
      <mapping.Icon height={isMobile ? '24px' : '30px'} />
      <Text isMobile={isMobile}>{mapping.text}</Text>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  border-radius: 8px;
  display: flex;
  padding: ${({ isMobile }) => (isMobile ? `0 20px` : `0 23px`)};
  margin: ${({ isMobile }) => (isMobile ? `7px` : `10px`)};
  height: ${({ isMobile }) => (isMobile ? `49px` : `60px`)};
  width: ${({ isMobile }) => (isMobile ? `145px` : `192px`)};
`;

const Text = styled.span`
  font-weight: bold;
  margin-left: 18px;
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: ${({ isMobile }) => (isMobile ? `13px` : `16px`)};
`;
