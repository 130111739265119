import { useObserver } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import useErnie from 'shared/hooks/use-ernie';
import { ModalNames, useModals } from 'src/components/modals';
import {
  useCreateBackInStockSubscriptionMutation,
  useDeleteBackInStockSubscriptionMutation,
  useGetBackInStockSubscriptionStatusQuery,
} from 'types/graphql';
import { tracker } from 'src/utils/analytics';

export default function useBackInStock(
  product,
  user
): {
  backInStockSubscription: boolean;
  handleUnsubscribeProduct: () => Promise<void>;
  handleSubscribeProduct: () => Promise<void>;
} {
  const showErnie = useErnie();
  const { openModal } = useModals();
  const isUserLoggedIn = useObserver(() => user.isLoggedIn);

  const { data: backInStockStatus } = useGetBackInStockSubscriptionStatusQuery({
    variables: {
      productId: product.id,
    },
    skip: !isUserLoggedIn,
    fetchPolicy: 'network-only',
  });
  const [createBackInStockSubscription] = useCreateBackInStockSubscriptionMutation();
  const [deleteBackInStockSubscription] = useDeleteBackInStockSubscriptionMutation();

  const [backInStockSubscription, setBackInStockSubscription] = useState(false);

  useEffect(() => {
    if (isUserLoggedIn && backInStockStatus?.getBackInStockSubscriptionStatus?.subscribed) {
      setBackInStockSubscription(true);
    }
  }, [backInStockStatus, setBackInStockSubscription, isUserLoggedIn]);

  const handleSubscribeProduct = async (): Promise<void> => {
    if (!isUserLoggedIn) {
      await openModal(ModalNames.login, 'back in stock');
    } else {
      const res = await createBackInStockSubscription({
        variables: {
          productId: product.id,
        },
      });

      if (res.data?.createBackInStockSubscription?.success) {
        setBackInStockSubscription(true);
        showErnie(`You will be notified when this product is back in stock`, 'success');
        tracker.gaGTMClickEvent({
          eventCategory: 'back in stock',
          eventAction: 'sign up',
          eventLabel: '',
        });
      }
    }
  };

  const handleUnsubscribeProduct = async (): Promise<void> => {
    const res = await deleteBackInStockSubscription({
      variables: {
        productId: product.id,
      },
    });
    if (res.data?.deleteBackInStockSubscription?.success) {
      setBackInStockSubscription(false);
      showErnie(`You have unsubscribed from this product`, 'success');
    }
  };

  return {
    backInStockSubscription,
    handleUnsubscribeProduct,
    handleSubscribeProduct,
  };
}
