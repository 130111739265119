import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { ModalContainer, ModalExitIcon } from 'components/core';
import { ModalWrapper } from 'shared/modals/v2-wrapper';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTranslation from 'hooks/use-translation';
import miscUtils from 'shared/utils/misc-utils';
import { Description, Subheading } from 'components/product/typography';

const TerpeneInfo = ({ terpene, isOpen, closeTerpene, isLastSection, modalRef }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const showModal = !!terpene && isOpen;
  const hasSecondaryInfo = terpene?.libraryTerpene?.effects?.length > 0 || terpene?.libraryTerpene?.aromas?.length > 0;
  const withColumns = terpene?.libraryTerpene?.description && hasSecondaryInfo;

  // Manually set focus to the modal (and w/o triggering focus scroll if the modal is already in view)
  const focusModal = () => {
    if (modalRef.current) {
      if (miscUtils.isScrolledIntoView(modalRef.current, 50)) {
        modalRef.current.focus({ preventScroll: true });
      } else {
        modalRef.current.focus();
      }
    }
  };

  // Custom Terp ModalWrapper Styles
  const terpModalStyles = {
    position: !isLastSection && isMobile ? `relative` : `absolute`,
    paddingBottom: !isLastSection && isMobile ? `0` : `75px`,
    top: `0`,
    bottom: `auto`,
    minHeight: `100%`,
  };

  return (
    <ModalWrapper
      open={showModal}
      onEscapeKeyDown={closeTerpene}
      hideBackdrop
      disablePortal
      disableScrollLock
      aria-labelledby='terp-modal-title'
      aria-describedby='terp-modal-desc'
      disableEnforceFocus
      disableAutoFocus
      onRendered={focusModal}
      style={terpModalStyles}
    >
      <StyledModalContainer ref={modalRef}>
        <ModalExitIcon onClick={closeTerpene} />
        <InfoWrap withColumns={withColumns}>
          <PrimaryInfo withColumns={withColumns}>
            <Heading>
              <Title id='terp-modal-title' tabindex='0'>
                {terpene?.libraryTerpene?.name}
              </Title>
            </Heading>
            <InfoDesc id='terp-modal-desc'>{terpene?.libraryTerpene?.description}</InfoDesc>
          </PrimaryInfo>
          <SecondaryInfo withColumns={withColumns}>
            {terpene?.libraryTerpene?.effects?.length > 0 && (
              <>
                <InfoHeading>{t('common.effect_plural', 'Effects')}</InfoHeading>
                <InfoList>{_.join(terpene.libraryTerpene.effects, ', ')}</InfoList>
              </>
            )}
            {terpene?.libraryTerpene?.aromas?.length > 0 && (
              <>
                <InfoHeading>{t('common.aroma_plural', 'Aromas')}</InfoHeading>
                <InfoList>{_.join(terpene.libraryTerpene.aromas, ', ')}</InfoList>
              </>
            )}
          </SecondaryInfo>
        </InfoWrap>
      </StyledModalContainer>
    </ModalWrapper>
  );
};

export default TerpeneInfo;

const StyledModalContainer = styled(ModalContainer)`
  top: -7px;
  width: 100% !important;
  min-height: 175px;
  transform: none;
  border-radius: 24px;
  padding: 27px 40px 37px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.097543);
  ${({ theme }) => theme.breakpoints.up('md')} {
    top: -18px;
  }
`;

const InfoWrap = styled.div`
  text-align: left;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${({ withColumns }) => withColumns && `flex`};
  }
`;

const PrimaryInfo = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ withColumns }) => withColumns && '70%'};
  }
`;

const SecondaryInfo = styled.div`
  padding-top: 35px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ withColumns }) => withColumns && '30%'};
    padding: 7px 0 0 43px;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 17px;
  }
`;

const Title = styled.h3`
  margin-top: 3px;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey[35]};
`;

const InfoDesc = styled(Description)`
  line-height: 21px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 12px;
    line-height: 19px;
  }
`;

const InfoHeading = styled(Subheading)`
  margin-bottom: 8px;
  text-transform: uppercase;
  line-height: 14px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[70]};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 6px;
    font-size: 11px;
    line-height: 16px;
  }
`;

const InfoList = styled(Description)`
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 35px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 27px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
