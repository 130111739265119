import React from 'react';
import _ from 'lodash';
import { layout } from 'styled-system';
import styled, { css } from 'styled-components';

import { getProductWeight } from 'shared/helpers/products';
import { formatDiscountForDisplay } from 'shared/helpers/specials';
import { useViewport } from 'contexts/viewport';
import { Clickable } from 'shared/components';

export default function OptionsList({
  disabled,
  onOptionSelect,
  options,
  product,
  selectedOption,
  showWeightSpecials,
  weightedSpecialPrices,
  hideDiscount,
}) {
  const { width: viewportWidth } = useViewport();
  const atleastOneWeightHasDiscount = _.some(weightedSpecialPrices, ({ discountPercentage }) => discountPercentage > 0);

  return (
    <Container width={viewportWidth} hasDiscount={atleastOneWeightHasDiscount}>
      {_.map(options, (option) => {
        const selected = option.value === selectedOption.value;
        const weight = getProductWeight(option.value);
        const price = _.find(weightedSpecialPrices, [`weight`, weight]);

        let discountLabel = null;
        if (showWeightSpecials) {
          discountLabel = formatDiscountForDisplay(price.standardPrice, price.price, product, `sale`, option.value);
        }

        const shouldShowStandardPrice =
          price && +price.standardPrice !== 0.0 && +price.price < +price.standardPrice && !!discountLabel;

        function handleClick() {
          onOptionSelect(option);
        }

        return (
          <ButtonContainer width={viewportWidth} key={option.value} hasDiscount={shouldShowStandardPrice}>
            <Button
              aria-checked={selected}
              disabled={disabled}
              hasDiscount={shouldShowStandardPrice && !hideDiscount}
              key={option.label}
              noBottomBorderRadius={!!discountLabel && !hideDiscount}
              onClick={handleClick}
              role='radio'
              selected={selected}
            >
              <Label>{option.label}</Label>
              {shouldShowStandardPrice && !hideDiscount && <StrikedOutPrice>${price.standardPrice}</StrikedOutPrice>}
              <BoldSpan hasDiscount={shouldShowStandardPrice && !hideDiscount}>{`$${Number(option.price).toFixed(
                2
              )}`}</BoldSpan>
              {discountLabel && !hideDiscount && (
                <DiscountLabel selected={!disabled && selected}>{discountLabel}</DiscountLabel>
              )}
            </Button>
          </ButtonContainer>
        );
      })}
    </Container>
  );
}

const DiscountLabel = styled.div`
  background: ${({ selected, theme }) =>
    selected ? theme.customized.colors.buttonsLinks : theme.colors.red[60]} !important;
  border-radius: 0 0 ${({ theme }) => theme.customized.radius.md} ${({ theme }) => theme.customized.radius.md};
  bottom: -10px;
  color: #fff;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  margin-top: -13px;
  padding: 2px 0;
  position: relative;
  text-align: center;
  top: 19px;
  width: inherit;
`;

const selectedStyles = css`
  border: ${({ theme }) => `2px solid ${theme.customized.colors.buttonsLinks}`};
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;

const ButtonContainer = styled.div`
  padding: 4.5px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 20px;
  }
`;

const Button = styled(Clickable)`
  align-items: center;
  border-radius: ${({ theme }) => theme.customized.radius.md};
  border: ${({ theme }) => `1px solid ${theme.colors.blueGrey[80]}`};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.grey[70] : theme.colors.primaryBlack)};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? `default` : `pointer`)};
  display: flex;
  flex-direction: column;
  font-size: 13px;
  height: ${({ hasDiscount }) => (hasDiscount ? `62px` : `59px`)};
  justify-content: center;
  padding: 0;
  width: 73px;
  min-width: 73px;
  line-height: ${({ hasDiscount }) => (hasDiscount ? `1` : `1.15`)};
  ${({ disabled, selected }) => !disabled && selected && selectedStyles};
  ${({ noBottomBorderRadius }) =>
    noBottomBorderRadius &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const Label = styled.span`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
`;

const StrikedOutPrice = styled.span`
  font-size: 12px;
  margin-top: 2.5px;
  color: ${({ theme }) => theme.colors.grey[70]};
  text-decoration-line: line-through;
`;

const BoldSpan = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-top: ${({ hasDiscount }) => (hasDiscount ? `2.5px` : `5px`)};
  margin-right: 0;
`;

const Container = styled.div`
  margin-bottom: 20px;
  margin-left: -20px;
  padding-left: 20px;
  height: ${({ hasDiscount }) => (hasDiscount ? `83px` : `75px`)};
  display: flex;
  overflow-x: auto;
  margin-top: 15px;

  ::-webkit-scrollbar {
    display: none;
  }

  ${layout}
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    margin-top: 18px;
  }
`;
