import React, { useMemo, ElementType } from 'react';
import useTranslation from 'hooks/use-translation';
import { TFunctionResult } from 'i18next';
import { GqlProducts } from 'types/graphql';
import DetailAccordion from './detail-accordion';
import sectionConfig, { ProductDetailData, SectionFlags, Section, getVisibleSections } from './detail-section-config';

type ProductDetailProps = ProductDetailData & SectionFlags;

type SectionProps = {
  effects?: GqlProducts['effects'];
  terpenes?: GqlProducts['terpenes'];
  cannabinoidsV2?: GqlProducts['cannabinoidsV2'];
  brand?: GqlProducts['brand'];
};

type DetailSectionProps = SectionProps & {
  title: TFunctionResult | string;
  isMobile: boolean;
  id: string;
  isFirstSection: boolean;
  isLastSection: boolean;
  component: ElementType;
};

export default function ProductDetails({ product, isMobile, ...flags }: ProductDetailProps): JSX.Element {
  const { t } = useTranslation();

  const visibleSectionData: DetailSectionProps[] = useMemo(() => {
    const visibleSectionNames = getVisibleSections(flags);
    return visibleSectionNames.map((sectionName, i) => {
      const name = Section[sectionName as keyof typeof Section];
      const { component, translateTitle } = sectionConfig[name];
      return {
        isMobile,
        component,
        id: `accordion-${name}`,
        isFirstSection: i === 0,
        isLastSection: i === visibleSectionNames.length - 1,
        [name]: product[name], // i.e. effects: product.effects
        title: translateTitle(t),
        product,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, flags]);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      {visibleSectionData.map(({ component: DetailComponent, ...props }) => (
        <DetailAccordion key={props.id} {...props}>
          <DetailComponent {...props} />
        </DetailAccordion>
      ))}
    </>
  );
}
