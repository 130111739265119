import { useEffect } from 'react';
import { GqlProducts } from 'types/graphql';
import useUI from 'src/hooks/use-ui';
import { tracker } from '..';

export const useViewedProductMonitor = (product?: GqlProducts): void => {
  const UI = useUI();

  useEffect(() => {
    if (product) {
      tracker.viewedProduct({ product, trackerSource: UI.activeProductSource });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id /* only trigger when the product ID changes */]);
};
