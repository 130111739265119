import React, { useEffect } from 'react';
import { BellDisabledIcon } from 'assets/bell-disabled-icon';
import { BellIcon } from 'assets/bell-icon';
import useTranslation from 'hooks/use-translation';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import { tracker } from 'src/utils/analytics';
import { GqlDispensaries } from 'types/graphql';

type BackInStockProps = {
  isUnavailable: boolean;
  backInStockSubscription: boolean;
  handleSubscribeProduct: () => void;
  handleUnsubscribeProduct: () => void;
  user: {
    isLoggedIn: boolean;
    id: string;
  };
  dispensary: GqlDispensaries;
};

const BackInStock = ({
  isUnavailable,
  backInStockSubscription,
  handleSubscribeProduct,
  handleUnsubscribeProduct,
  user,
  dispensary,
}: BackInStockProps): JSX.Element => {
  const { t } = useTranslation();
  const isUserLoggedIn = useObserver(() => user.isLoggedIn);

  useEffect(() => {
    if (isUnavailable) {
      tracker.gaGTMClickEvent({
        eventCategory: 'back in stock',
        eventAction: 'impression',
        eventLabel: '',
      });
    }
  }, [dispensary.id, dispensary.name, user.id, isUnavailable]);

  return (
    <>
      {isUnavailable && (!backInStockSubscription || !isUserLoggedIn) && (
        <BackInStockWrapper>
          <BackInStockNotifyButton onClick={handleSubscribeProduct}>
            <BellIcon />
            <BackInStockNotifyText>Notify me when it's back</BackInStockNotifyText>
          </BackInStockNotifyButton>
          <OutOfStockMessage>
            {t('marketplace.unavailable-product', `Get notified when this item comes back in stock`)}
          </OutOfStockMessage>
        </BackInStockWrapper>
      )}
      {backInStockSubscription && isUserLoggedIn && (
        <BackInStockWrapper>
          <BackInStockNotifyUnsubscribeButton onClick={handleUnsubscribeProduct}>
            <BellDisabledIcon />
            <BackInStockNotifyText>Unsubscribe</BackInStockNotifyText>
          </BackInStockNotifyUnsubscribeButton>
          <BackInStockSubscriptionSignedUpText>
            <span>You're signed up.</span> We'll email you when it's back in stock.
          </BackInStockSubscriptionSignedUpText>
        </BackInStockWrapper>
      )}
    </>
  );
};

export default BackInStock;

const BackInStockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const BackInStockNotifyButton = styled.button`
  display: flex;
  padding: 1rem 1.5rem;
  width: fit-content;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.sapphire};
  color: #ffffff;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: #0064c8;
  }
`;

const BackInStockNotifyText = styled.div`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.00406rem;
  line-height: 1.125rem; /* 138.462% */
  text-transform: uppercase;
`;

const OutOfStockMessage = styled.div`
  color: #828a8f;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00406rem;
  line-height: 1.125rem; /* 138.462% */
`;

const BackInStockNotifyUnsubscribeButton = styled.button`
  display: flex;
  padding: 1rem 1.5rem;
  width: 10.3125rem;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 6.25rem;
  background-color: #e3e7e9;
  color: #121516;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: #caced4;
  }
`;

const BackInStockSubscriptionSignedUpText = styled.div`
  color: #828a8f;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.00406rem;
  line-height: 1.125rem;

  span {
    color: #00a47c;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.00406rem;
    line-height: 1.125rem; /* 138.462% */
  }
`;
