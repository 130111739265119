import React from 'react';

function SvgFocused(props) {
  return (
    <svg fill='none' viewBox='0 0 50 50' {...props}>
      <g clipRule='evenodd' fillRule='evenodd'>
        <path
          d='M20.755 48.113c10.42 0 18.868-8.447 18.868-18.868 0-10.42-8.448-18.868-18.868-18.868S1.887 18.825 1.887 29.245c0 10.42 8.447 18.868 18.868 18.868z'
          fill='#fff'
        />
        <path d='M30.66 8.962l1.296 7.91 8.995 2.547 6.924-6.92-6.924-2.122-3.139-7.48z' fill='#fff' />
        <path
          d='M23.456 24.668l4.606-4.606a11.647 11.647 0 00-7.277-2.54c-6.461 0-11.693 5.232-11.693 11.693A11.684 11.684 0 0020.785 40.91c6.46 0 11.694-5.232 11.694-11.694 0-2.751-.951-5.28-2.54-7.277l-4.606 4.605c2.043 3.46-.436 7.949-4.548 7.949a5.276 5.276 0 01-5.277-5.277c0-4.112 4.487-6.589 7.948-4.547zm-1.733 5.485c-1.233 1.233-3.11-.643-1.876-1.876l1.6-1.6c-1.652-.427-3.284.807-3.284 2.538a2.622 2.622 0 002.622 2.623c1.734 0 2.964-1.632 2.539-3.286zm8.738-16.273a18.052 18.052 0 00-9.676-2.796c-10.014 0-18.131 8.117-18.131 18.131s8.117 18.131 18.131 18.131 18.131-8.117 18.131-18.13c0-3.559-1.025-6.877-2.796-9.677l-2.862-.92-1.434 1.433a14.292 14.292 0 013.31 9.163c0 7.919-6.43 14.348-14.349 14.348-7.916 0-14.348-6.432-14.348-14.348 0-7.92 6.43-14.348 14.348-14.348 3.483 0 6.678 1.244 9.163 3.308l1.433-1.433zm-9.676-5.45c3.031 0 5.91.65 8.508 1.817l-.353-1.099L38.088 0l2.898 9.014L50 11.912l-9.149 9.148-1.097-.353a20.713 20.713 0 011.816 8.508C41.57 40.694 32.263 50 20.785 50 9.306 50 0 40.694 0 29.215 0 17.737 9.306 8.43 20.785 8.43zm13.155 7.63l6.174 1.984 4.944-4.942-6.174-1.986-1.986-6.173-4.942 4.943z'
          fill='#64737f'
        />
      </g>
    </svg>
  );
}

export default SvgFocused;
