import React, { useState } from 'react';
import _ from 'lodash';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import CustomButtonGroup from 'components/product-specials-carousel/product-specials-carousel-controls';
import SpecialOffersChip from 'components/core/special-offers-chip';
import ProductSpecialsCarouselCard from 'components/product-specials-carousel/product-specials-carousel-card';

const responsive = { all: { breakpoint: { max: 4000, min: 0 }, items: 1 } };

const ProductSpecialsCarousel = ({ specials, linkColor }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  return (
    <>
      <SpecialOffersChip size='large' currentSlide={currentSlideIndex + 1} totalSlides={specials.length} />
      <Carousel
        arrows={false}
        draggable={false}
        responsive={responsive}
        renderButtonGroupOutside
        customButtonGroup={<CustomButtonGroup dotCount={specials.length} dotColor={linkColor} />}
        afterChange={(_previousSlide, { currentSlide }) => {
          setCurrentSlideIndex(currentSlide);
        }}
      >
        {_.map(specials, (special) => (
          <ProductSpecialsCarouselCard key={`special-${special.specialId}`} linkColor={linkColor} special={special} />
        ))}
      </Carousel>
    </>
  );
};

export default ProductSpecialsCarousel;
