export const getColorVariant = (baseHex, amount) => {
  baseHex = baseHex.replace(/^#/, '');
  if (baseHex.length === 3) {
    baseHex = baseHex[0] + baseHex[0] + baseHex[1] + baseHex[1] + baseHex[2] + baseHex[2];
  }

  let [r, g, b] = baseHex.match(/.{2}/g);
  [r, g, b] = [parseInt(r, 16) + amount, parseInt(g, 16) + amount, parseInt(b, 16) + amount];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};

export const checkColorTone = (color) => {
  // Convert HEX value to RGB: http://gist.github.com/983661
  color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

  const r = color > 16;
  const g = color > 8 && 255;
  const b = color && 255;

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value calculated above, determine whether the color is light, dark or medium
  if (hsp > 140) {
    return `light`;
  }
  if (hsp < 110) {
    return `dark`;
  }
  return `medium`;
};

export const getStepFactor = (count) => {
  if (count > 15) {
    return 10;
  }

  if (count > 10) {
    return 20;
  }

  if (count > 5) {
    return 30;
  }

  return 40;
};
