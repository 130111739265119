import React from 'react';

function SvgUplifted(props) {
  return (
    <svg fill='none' viewBox='0 0 51 50' {...props}>
      <g clipRule='evenodd' fillRule='evenodd'>
        <path
          d='M25.11 12.02s-5.967.301-8.37 4.336c-2.403 4.034-.93 7.927-.93 7.927l2.02 4.463h15.606l1.9-7.426-.926-4.964-3.876-2.86z'
          fill='#fff'
        />
        <path
          d='M23.25 7.576c0 .928.859 1.724 1.86 1.724s1.86-.796 1.86-1.724V1.724C26.97.796 26.111 0 25.11 0s-1.86.796-1.86 1.724zM4.517 20.46c-.93 0-1.727.859-1.727 1.86s.797 1.86 1.727 1.86h4.916c.93 0 1.727-.859 1.727-1.86s-.797-1.86-1.727-1.86zm43.843 1.86c0 1.002-.808 1.86-1.752 1.86h-5.795c-.944 0-1.753-.859-1.753-1.86s.81-1.86 1.753-1.86h5.795c.944 0 1.752.859 1.752 1.86zm-33.07-9.847a1.8 1.8 0 000-2.532l-3.833-3.848c-.694-.684-1.873-.684-2.567 0a1.8 1.8 0 000 2.531l3.902 3.849c.347.342.833.547 1.249.547.486 0 .902-.205 1.249-.547zm19.641-.002a1.804 1.804 0 010-2.537l3.895-3.84c.695-.685 1.877-.685 2.503 0a1.804 1.804 0 010 2.537l-3.894 3.84c-.348.343-.834.549-1.251.549-.418 0-.905-.206-1.253-.549zm-1.495 10.106c0 1.924-.727 3.784-1.982 5.259H18.766c-1.255-1.475-1.982-3.271-1.982-5.26 0-4.425 3.7-8.08 8.326-8.08s8.326 3.655 8.326 8.08zm15.066 5.708h-13.15c.991-1.732 1.586-3.656 1.586-5.708 0-6.286-5.286-11.417-11.762-11.417-6.476 0-11.762 5.131-11.762 11.417 0 2.052.529 3.976 1.586 5.708H1.718c-.925 0-1.718.77-1.718 1.667s.793 1.668 1.718 1.668h46.784c.925 0 1.718-.77 1.718-1.668s-.793-1.667-1.718-1.667zM7.44 36.27c0-1.002.776-1.86 1.682-1.86h15.236c.906 0 1.682.859 1.682 1.86s-.776 1.86-1.682 1.86H9.122c-.906 0-1.682-.859-1.682-1.86zm19.352 2.79c-.906 0-1.682.858-1.682 1.86s.776 1.86 1.682 1.86h15.236c.906 0 1.682-.859 1.682-1.86s-.776-1.86-1.682-1.86zM11.1 44.64c0-1.002.781-1.86 1.692-1.86h8.765c.912 0 1.693.858 1.693 1.86 0 1.002-.781 1.86-1.693 1.86h-8.765c-.911 0-1.692-.858-1.692-1.86z'
          fill='#64737f'
        />
      </g>
    </svg>
  );
}

export default SvgUplifted;
