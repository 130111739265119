import _map from "lodash/map";
import _join from "lodash/join";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _trim from "lodash/trim";
import _replace from "lodash/replace";
import _flow from "lodash/flow";
export function isDescriptionHtmlBlank(html) {
  // Strip off p and br tags, clean blankspace, and check for empty
  return _flow([function (str) {
    return _replace(str, /<\/?p>/g, '');
  }, function (str) {
    return _replace(str, /<\/?br>/g, '');
  }, _trim, _isEmpty])(html);
}
export function getDescriptionHtml(product) {
  var returnValue = '';

  if (product) {
    var description = _get(product, 'Description', '') || '';
    var descriptionHtml = _get(product, 'descriptionHtml', '') || '';
    var blankDescriptionHtml = isDescriptionHtmlBlank(descriptionHtml);

    if (blankDescriptionHtml) {
      returnValue = _join(_map(description.split('\n'), function (line) {
        return "<p>".concat(_isEmpty(line) ? ' ' : line, "</p>");
      }), '');
    } else {
      returnValue = descriptionHtml;
    }
  }

  return returnValue;
} // Expects to only work in the browser, where DOMParser is available.

export function getSimpleDescription(product, Parser) {
  if (!Parser) {
    console.log('DOMParser not available in this context. Is this a browser?');
    return '';
  }

  var productDescription = getDescriptionHtml(product);
  var parserInst = new Parser();
  return parserInst.parseFromString(productDescription, 'text/html').documentElement.textContent;
}
export default {
  getSimpleDescription: getSimpleDescription,
  getDescriptionHtml: getDescriptionHtml,
  isDescriptionHtmlBlank: isDescriptionHtmlBlank
};