import React, { PropsWithChildren, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { Clickable, Tooltip } from 'shared/components';
import InfoIcon from 'src/assets/info-icon';

import useUI from 'hooks/use-ui';
import useViewport from 'hooks/use-viewport';
import useTranslation from 'hooks/use-translation';

import { ModalContainer, Button } from 'components/core';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { heading3, paragraph } from 'components/core/typography';

export type MoreInfoProps = PropsWithChildren<{
  infoTitle: string;
  infoBody: ReactElement;
  tooltipDimensions?: {
    top?: string;
    right?: string;
    left?: string;
    bottom?: string;
    width?: string;
  };
}>;

const TooltipDefaults = {
  top: 'auto',
  right: '-37px',
  bottom: '50px',
  left: 'auto',
  width: undefined,
} as const;

export function MobileInfoWrapper({ children, infoTitle, infoBody }: MoreInfoProps): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const UI = useUI();
  const viewport = useViewport();
  const { t } = useTranslation();

  return (
    <InfoChipPill onMouseDown={() => setDrawerOpen(true)}>
      {children}
      <TooltipIcon />

      <ModalWrapper open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <StyledModalContainer
          isIframe={UI.isIframe}
          iframeOffset={viewport.offset}
          parentOffset={Number(viewport.parentOffset) + 75}
          viewportHeight={viewport.height}
          viewportWidth={viewport.width}
          iframeHeight={UI.iframeHeight}
        >
          <Title>{infoTitle}</Title>
          <Info>{infoBody}</Info>
          <StyledButton variant='primary' onClick={() => setDrawerOpen(false)}>
            {t('common.close', 'Close')}
          </StyledButton>
        </StyledModalContainer>
      </ModalWrapper>
    </InfoChipPill>
  );
}

export function DesktopInfoWrapper({ children, tooltipDimensions, infoBody }: MoreInfoProps): JSX.Element {
  const dimensions = { ...TooltipDefaults, ...(tooltipDimensions ?? {}) };

  return (
    <Tooltip
      {...dimensions}
      hoverMain
      mainCopy={
        <InfoChipPill interactive>
          {children}
          <TooltipIcon />
        </InfoChipPill>
      }
      hideIcon
      tooltipCopy={infoBody}
    />
  );
}

const TooltipIcon = styled(InfoIcon)`
  --size: 14px;
  height: var(--size);
  width: var(--size);
  margin-right: 14px;
`;

const StyledModalContainer = styled(ModalContainer)`
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  top: 35%;
`;

const Title = styled.h1`
  ${heading3};
  text-align: left;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack[15]};
  align-self: flex-start;
`;

const Info = styled.p`
  ${paragraph};
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 32px;
  line-height: 24.5px;
  color: ${({ theme }) => theme.colors.grey[30]};
`;

const StyledButton = styled(Button)`
  width: 285px;
`;

export const InfoChipPill = styled(Clickable)`
  border-radius: 61px;
  background-color: ${({ theme }) => theme.colors.grey[95]};
  margin: 0 10px 10px 0;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: ${({ interactive }) => (interactive ? 'pointer' : undefined)};
`;
