import React, { ReactNode, ElementType, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isNotNullish } from 'shared/utils/type-utils';
import { useMediaQuery } from '@material-ui/core';
import { muiTheme } from 'shared/themes';
import { MoreInfoProps, MobileInfoWrapper, DesktopInfoWrapper, InfoChipPill } from './more-info';

function determineWrapper({ isInfoChip, isMobile }: Record<string, boolean>): ElementType {
  if (!isInfoChip) {
    return InfoChipPillWrapper;
  }
  if (isMobile) {
    return MobileInfoWrapper;
  }
  return DesktopInfoWrapper;
}

export const TEST_IDS = {
  infoChip: `info-chip`,
};

export type InfoChipProps = {
  highlight?: boolean;
  id: string;
  moreInfo?: MoreInfoProps;
  text: ReactNode;
  titleCase?: boolean;
  onClick?: () => void;
};

export function InfoChip({
  id,
  highlight = false,
  moreInfo,
  text,
  titleCase = false,
  onClick,
}: InfoChipProps): JSX.Element {
  const isInfoChip = isNotNullish(moreInfo);
  const isMobile = useMediaQuery<typeof muiTheme>((theme) => theme.breakpoints.down(`sm`));

  const Wrapper = useMemo(() => determineWrapper({ isInfoChip, isMobile }), [isInfoChip, isMobile]);

  return (
    <Wrapper onClick={onClick} role={id === 'strainType' ? 'link' : 'button'} {...(isInfoChip ? moreInfo : {})}>
      <InfoChipText
        titleCase={!!titleCase}
        highlight={!!highlight}
        isInfoChip={isInfoChip}
        data-testid={TEST_IDS.infoChip}
      >
        {text}
      </InfoChipText>
    </Wrapper>
  );
}

const highlightStyles = css`
  font-weight: bold;
`;
const titleCaseStyles = css`
  text-transform: capitalize;
`;

const InfoChipText = styled.span`
  ${({ highlight }) => (highlight ? highlightStyles : null)}
  ${({ titleCase }) => (titleCase ? titleCaseStyles : null)}
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 13px;
  padding: ${({ isInfoChip }) => (isInfoChip ? '14px 6px 14px 18px' : '14px 18px')};
  cursor: pointer;
`;

const InfoChipPillWrapper = styled(InfoChipPill)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[80]};
  }
`;
