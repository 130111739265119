import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import styled, { useTheme } from 'styled-components';
import dynamic from 'next/dynamic';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { getColorVariant, getStepFactor, checkColorTone } from 'utils/helpers/color';
import { useAmplitude } from 'shared/hooks/use-amplitude';
import { linkColors } from 'shared/constants/menu-colors';
import TerpeneList from './components/terpene-list';

const TerpenePieChart = dynamic(() => import('./components/terpene-pie-chart'), { ssr: false });

export default function Terpenes({ terpenes, isMobile, isLastSection, product }) {
  const [activeTerpene, setActiveTerpene] = useState(null); // the terpene currently highlighted
  const [openedTerpene, setOpenedTerpene] = useState(null); // the terpene currently open
  const [infoOpen, setInfoOpen] = useState(false);
  const [sectionHeight, setSectionHeight] = useState(false);
  const { customized } = useTheme();
  const modalTriggerRef = useRef(); // most recent terp to trigger an info modal
  const modalRef = useRef();
  const amplitude = useAmplitude();
  const { dispensary } = useDispensary();

  const baseColor = _.get(dispensary, `colorSettings.linkColor`);

  // Adjust desktop section height to account for modal height
  useEffect(() => {
    if (infoOpen && !isMobile && !isLastSection) {
      setSectionHeight(modalRef.current?.clientHeight);
    }

    return () => setSectionHeight(false);
  }, [infoOpen, isLastSection, isMobile]);

  const valuedTerpenes = _.filter(terpenes, `value`); // grab only the terpenes that have associated values
  const hasValues = !!valuedTerpenes.length;

  // Calculate the total value of the product's terpene profile
  const totalTerpsValue = _.reduce(valuedTerpenes, (sum, terpene) => sum + terpene.value, 0);

  // Set some constants around our color stepping/gradation logic
  const defaultColorHex = customized.colors.buttonsLinks;
  const baseColorHex = _.find(linkColors, [`key`, baseColor])?.background || defaultColorHex;
  const baseColorTone = checkColorTone(baseColorHex);
  const stepRange = valuedTerpenes.length % 2 === 0 ? valuedTerpenes.length : valuedTerpenes.length - 1;
  const stepRangeOrigin = stepRange / 2; // the median index in the range of terpenes that have values
  const stepFactor = getStepFactor(valuedTerpenes.length);

  // Determine a reasonable color step amount based on the item index
  const getColorStep = useCallback(
    (index, origin) => {
      // For super light base colors, skew the color step distribution darker
      // Turns out, the light colors actually look OK using the even distribution
      // used for mid-tone colors below. Keeping this here though in case product disagrees.
      // if (baseColorTone === "light") {
      //   return index * -(stepFactor - 15);
      // }

      // For super dark base colors, skew the color step distribution lighter
      if (baseColorTone === 'dark') {
        return index * stepFactor;
      }

      // For all other semi-light or mid-tone base colors, evenly distribute the color steps between dark & light...

      // if the terpene's index is less than the step range origin,
      // multiply by the step factor in a negative direction (darker color)
      if (index < origin) {
        return (origin - index) * -stepFactor;
      }

      // if the terpene's index is greater than the step range origin,
      // multiply by the step factor in a positive direction (lighter color)
      if (index > origin) {
        return (index - origin) * stepFactor;
      }

      // if the terpene's index matches the step range origin, just use the base color
      return 0;
    },
    [baseColorTone, stepFactor]
  );

  // Next, shuffle the terpenes and then add a unique color gradation hex value
  // (as a factor of the base color & terpene index) to each terp

  // Note: Shuffling the terps here first prevents us from always applying darker
  // or lighter colors to the weightier terpenes in the profile.

  // Wrap this array in a useMemo so that our color assignemnts persist across renders
  // and then pass it down to the TerpeneList
  const coloredTerpenes = useMemo(
    () =>
      _.map(_.shuffle(terpenes), (terpene, index) => {
        if (!terpene.value) {
          return terpene;
        }
        const stepAmount = getColorStep(index, stepRangeOrigin);
        const colorVariant = stepAmount === 0 ? baseColorHex : getColorVariant(baseColorHex, stepAmount);

        return { ...terpene, color: colorVariant };
      }),
    [baseColorHex, getColorStep, stepRangeOrigin, terpenes]
  );

  // Additionally for the pie chart only: grab, shuffle and store only the now colorized terpenes that have values
  // This ensures that only terps with values make it onto the chart,...
  // that chart segments of similar colors don't sit next to each other,...
  // and that the randomization persists across renders
  // Then pass it down to the TerpenePieChart
  const shuffledValuedTerpenes = useMemo(() => _.shuffle(_.filter(coloredTerpenes, `value`)), [coloredTerpenes]);

  // Activate a terpene
  function activateTerpene(terpene) {
    if (!activeTerpene || activeTerpene.libraryTerpene?.name !== terpene.libraryTerpene?.name) {
      setActiveTerpene(terpene);
    }
  }

  // Deactivate any active terpene
  function deactivateTerpene() {
    if (activeTerpene) {
      setActiveTerpene(null);
    }
  }

  // Open the terpene info modal,
  // Store the element that triggered the modal in a ref so that we can manually restore focus to it later
  function openInfoModal(e, terpene) {
    if (e) {
      modalTriggerRef.current = e.currentTarget;
    }

    if (!infoOpen) {
      setInfoOpen(true);
    }

    setOpenedTerpene(terpene);
  }

  // Close the terpene info modal, deactivate the active terp
  function closeInfoModal() {
    // Restore focus to the specific terp that triggered the info modal
    if (modalTriggerRef.current) {
      modalTriggerRef.current.focus();
    }

    if (infoOpen) {
      setInfoOpen(false);
    }
    if (activeTerpene) {
      deactivateTerpene();
    }
    setOpenedTerpene(null);
  }

  // Hover/Focus On (desktop - supports keyboard a11y)
  function handleFocusOn(terpene) {
    if (!isMobile) {
      activateTerpene(terpene);
    }
  }

  // Hover/Focus Off (desktop - supports keyboard a11y)
  function handleFocusOff() {
    if (!isMobile) {
      deactivateTerpene();
    }
  }

  // Click handler (mobile + desktop)
  function handleTerpeneClick(e, terpene) {
    amplitude.log(`Clicked Terpene`, {
      description: `User clicks a specific terpene within the product detail page`,
      terpeneName: terpene.libraryTerpene?.name,
      productId: product.id,
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
    });

    if (terpene) {
      activateTerpene(terpene);
      openInfoModal(e, terpene);
    }
  }

  return (
    <Container minHeight={sectionHeight}>
      <Wrapper>
        {hasValues && (
          <TerpenePieChart
            terpenes={shuffledValuedTerpenes}
            totalValue={totalTerpsValue}
            activeTerpene={activeTerpene}
            handleFocusOn={handleFocusOn}
            handleFocusOff={handleFocusOff}
            handleTerpeneClick={handleTerpeneClick}
            isMobile={isMobile}
          />
        )}
        <TerpeneList
          terpenes={coloredTerpenes}
          activeTerpene={activeTerpene}
          handleFocusOn={handleFocusOn}
          handleFocusOff={handleFocusOff}
          handleTerpeneClick={handleTerpeneClick}
          openedTerpene={openedTerpene}
          openInfoModal={openInfoModal}
          closeInfoModal={closeInfoModal}
          infoOpen={infoOpen}
          hasValues={hasValues}
          isLastSection={isLastSection}
          isMobile={isMobile}
          modalRef={modalRef}
        />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : `auto`)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row-reverse;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
    position: relative;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 15px;
  }
`;
